import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import {colors} from "Shared/colors";
import ToggleSwitch from "./ToggleSwitch";
import {Storage} from "Shared/Services/storage";

const Container = styled.div`
  height: 40px;
  background-color: ${colors.SCRIPTER_PREVIEW_BLACK};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VisualFilter = () => {
    const [showHighlight, setShowHighlight] = useState(true);

    useEffect(() => {
        const shouldShowHighlighter = Storage.GetItem("u1st_errors_highlight");
        if (shouldShowHighlighter !== "false") {
            setShowHighlight(true);
            window.parent.postMessage({type: "show_errors_highlight"}, "*");
        }else{
            setShowHighlight(false);
        }
        return () =>
            window.parent.postMessage({type: "hide_errors_highlight"}, "*");
    }, []);

    const handleOnClick = () => {
        Storage.SetItem("u1st_errors_highlight", !showHighlight);
        setShowHighlight((showHighlight) => !showHighlight);
        window.parent.postMessage(
            {type: `${showHighlight ? "hide" : "show"}_errors_highlight`},
            "*"
        );
    };

    return (
        <Container>
            <ToggleSwitch
                checked={showHighlight}
                onClick={handleOnClick}
                checkedChildren="Visual Filter"
                unCheckedChildren="Off"
            />
        </Container>
    );
};

export default VisualFilter;
