export const deleteFromArray = (arr, value, comparasionParam) => {
    const idx = arr.findIndex(item => value === item[comparasionParam])
    arr.splice(idx, 1);
}

export function resetArr(arrs) {
    if (!arrs[0]) return
    if (Array.isArray(arrs[0])) {
        arrs.forEach(arr => {
            arr.length = 0
        })
    }
    arrs.length = 0
}
