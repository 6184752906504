import React, { useEffect,useRef } from 'react';
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { StyledModal } from 'Main/Shared/ModalStyles';
import { useVisibilityContext } from 'Main/Context/ElementVisibilityContext';
import {Input, Button} from 'antd';
import { toastError } from 'Shared/utils';
import {TooltipLabel} from 'Shared/Components/TooltipLabel';

const PackageModal = () => {

    const { packageModalVisiblity, setPackageModalVisiblity } = useVisibilityContext();
    const domainRef = useRef("");
    const [{ data: packageData, loading: packageLoading, error: packageError },
        generatePackage,] = useAxios(
            {
                method: "POST",
                url: `/package`,
                headers: {
                    "content-type": "application/json",
                },
                responseType: 'arraybuffer'
            },
            { manual: true }
        );
    
    const [{ data: isValid, loading, error: validationError },
        serverValidation] = useAxios(
            {
                method: "POST",
                url: `/package/validate`,
                headers: {
                    "content-type": "application/json",
                }
            },
            { manual: true }
        );

    const [
        { data: publishData, loading: publishLoading, error: publishError },
        publishScripts,
    ] = useAxios(
        {
            method: "POST",
            url: `/scripter/publish`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const publish = () => {
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                publishScripts({
                    data: { url: url },
                });
            }
        })();
    };

    const validate = () => {
        const domain = domainRef.current.input.value;
        if(!domain){
            toastError("domain cannot be empty");
            return;
        }
        serverValidation({data: {domain}})       
    }

    useEffect(() => {
        isValid && generate();
    }, [isValid])

    useEffect(() => {
        validationError && toastError(validationError?.response?.data?.Errors);
    }, [validationError]) 
    
    useEffect(() => {
        publishError && toastError(publishError?.response?.data?.Errors);
    }, [publishError])

    const generate = () => { 
        publish();
        const value = domainRef.current.input.value;
        if(value.trim()){
            (async () => {
                const url = await getCurrentUrl();
                generatePackage({data: {
                    url,
                    domain: value
                }})
            })()
        }
    }

    useEffect(() => {
        if(packageData){
            const file = new Blob([packageData], { type: 'application/zip' })
			const fileURL = URL.createObjectURL(file)
			const link = document.createElement('a')
			link.href = fileURL
			link.download = `u1stPackage.zip`
			link.click()
        }
    }, [packageData])

    return (
        <StyledModal
            mask="false"
            title={`Generate on premise package`}
            visible={packageModalVisiblity}
            footer={[
                <Button 
                    onClick={() => setPackageModalVisiblity(false)}>
                        CANCEL
                </Button>,
                <Button 
                    onClick={validate}
                    type="primary"
                    loading={packageLoading}>
                        PUBLISH AND DOWNLOAD
                </Button>,
            ]}
        >
            <div>
                <TooltipLabel htmlFor="serverDomain" tooltip={{text: "full domain of the elocated server"}} value="Server Domain"/>
                <Input placeholder="https://www.example.com" required ref={domainRef}/>
            </div>
        </StyledModal>
    )

};

export default PackageModal;