import { deleteFromArray, resetArr } from "../utils/utils"

const actions = []
const deletedKeys = []

export const ACTION_TYPE = {
    ADD: 'add',
    UPDATE: 'update',
    DELETE: 'delete'
}

export const addAction = (actionType, data, execute, previousDomain = '') => {
    const isExist = handleIsActionExist(data, actionType, execute)
    if (isExist) return

    let actionObj = {
        recordKey: data.key,
        actionType
    }

    switch (actionType) {
        case ACTION_TYPE.ADD:
            actionObj = { ...actionObj, action: () => { upsert(cleanData(data), execute) } }
            break;
        case ACTION_TYPE.UPDATE:
            actionObj = { ...actionObj, action: () => { upsert(cleanData(data, previousDomain), execute) } }
            break;
        case ACTION_TYPE.DELETE:
            deletedKeys.push(data.key)
            actionObj = { ...actionObj, action: () => { remove(cleanData(data), execute) } }
            break;
        default:
            break;
    }

    actions.push(actionObj)
}

function buildAction(recordKey, actionType, action) {
    return {
        recordKey,
        actionType,
        action
    }
}

function handleIsActionExist(requestedAction, requestedActionType, execute) {
    const { key } = requestedAction
    const actionIdx = actions.findIndex(action => action.recordKey === key)
    if (actionIdx < 0) return false
    const action = actions[actionIdx]

    if (action.actionType === ACTION_TYPE.ADD && requestedActionType !== ACTION_TYPE.DELETE) {
        actions.splice(actionIdx, 1, buildAction(action.recordKey, ACTION_TYPE.ADD, () => { upsert(cleanData(requestedAction), execute) }));
        return true
    }
    if (
        (action.actionType === ACTION_TYPE.DELETE && requestedActionType === ACTION_TYPE.UPDATE)
        || (action.actionType === ACTION_TYPE.UPDATE && requestedActionType !== ACTION_TYPE.DELETE)
    ) {
        actions.splice(actionIdx, 1, buildAction(action.recordKey, ACTION_TYPE.UPDATE, () => { upsert(cleanData(requestedAction), execute) }));
        return true
    }
}

export function deleteAction(key) {
    deleteFromArray(actions, key, 'recordKey')
}

export function getActions() {
    return [...actions]
}

export function hasDeleteAction(key) {
    const deletedKey = deletedKeys.find(item => item === key)
    if (deletedKey) return true
    return false
}

export function hadEditAction(key) {
    const isEdited = actions.find(item => item.key === key && item.actionType === ACTION_TYPE.UPDATE)
    if (isEdited) return true
    return false
}

export function undoAction(key) {
    const deletedKeysIdx = deletedKeys.findIndex(item => item === key)
    if (deletedKeysIdx === -1) return
    deletedKeys.splice(deletedKeysIdx, 1)

    const actionIdx = actions.findIndex(item => item.recordKey === key)
    if (actionIdx === -1) return
    actions.splice(actionIdx, 1)
}

export function undoAll() {
    resetArr([actions, deletedKeys])
}

export async function publish(deleteRowData) {
    await disptachActions()
    resetArr(actions)
    deleteRowData(deletedKeys)
    resetArr(deletedKeys)
}

async function disptachActions() {
    for (let i = 0; i < actions.length; i++) {
        try {
            await actions[i].action()
        } catch (e) {
            console.log(`Error dispatching action ${action[i].actionType} of ${action[i].data} - ${e?.message}`);
        }
    }
}


async function upsert(data, execute) {
    await execute({ data })
}

async function remove({ domain }, execute) {
    await execute({
        data: {
            domain
        }
    })
}

function cleanData({ siteId, domain, desktop, mobile }, previousDomain) {

    const obj = {
        siteId,
        domain,
        desktop,
        mobile
    }

    if (previousDomain) {
        obj['previousDomain'] = previousDomain
    }

    return obj
}