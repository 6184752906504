import React from 'react';
import { backgrounds, colors } from 'Shared/colors';
import { MESSAGE_STATE } from 'Shared/consts';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  z-index: 100;
  max-width: 600px;
  color: ${colors.WHITE} ;
`;

const StyledCardHeader = styled.div`

  background: ${backgrounds.PURPLE_90DEG};
  margin: auto auto 0px auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid black;
  box-shadow: 4px 6px 15px 3px rgba(0, 0, 0, 0.43);
  font-size: 1rem;
  padding: 12px ;
`;

const StyledU1Card = styled.div`
  background-color: ${colors.GREY_700};
  color: ${colors.WHITE};
  position: relative;
  text-align: start;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 2px 14px 19px 3px rgba(0, 0, 0, 0.71);
  font-weight: 400;

`;



const MsgIcon = styled.div`
  display: inline-flex;
  width: 8px;
  border-radius: 3px;
  margin-right: 1rem;
  background: ${({ messageState }) => (messageState === MESSAGE_STATE.SUCCESS
    ? colors.GREEN_SUCCESS
    : messageState === MESSAGE_STATE.ERROR
      ? colors.RED_ERROR
      : colors.YELLOW_WARNING)};
`;

const StyledMessage = styled.div`
  display: flex;
  position: relative;
  height: fit-content;
  transition: 1s;
  padding: 1rem;
  width: fit-content;
  font-size: 1rem;
`;

const StyledContent = styled.div`
  width: 100%;
  position: relative;
  overflow-wrap: break-word;
  text-align: left;
`;


const CardWithMessage = ({ children, title, messageState }) => {

  return (
    <StyledWrapper>
      <StyledCardHeader>{title}</StyledCardHeader>
      <StyledU1Card>
        <StyledMessage>
          <MsgIcon messageState={messageState} />
          <StyledContent>{children}</StyledContent>
        </StyledMessage>
      </StyledU1Card>
    </StyledWrapper>
  );
};

export default CardWithMessage;
