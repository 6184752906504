import React, { useEffect } from 'react';
import { Popconfirm, } from 'antd';
import UButton from 'Shared/Components/Button/UButton';
import { PlusOutlined, UndoOutlined, UploadOutlined, RollbackOutlined, FileTextOutlined } from '@ant-design/icons';
import { useVisibilityContext } from 'Main/Context/ElementVisibilityContext';
import { getActions, undoAll } from '../../services/domain.service';
import useAxios from "axios-hooks";
import { downloadReport } from '../../services/report.service';
import { useDomainContext } from '../../context/DomainContext';
import { HeaderOperatioContainer, StyledButtonsContainer, StyledSearchInput } from './style';
import { clearProps } from '../../services/table.service';
import CardWithMessage from 'Shared/Components/CardWithMessage';
import { MESSAGE_STATE } from 'Shared/consts';

export const HeaderOperations = ({ onSearch, searchTerm, dataSource, setIsNewAddedRow }) => {
    const { setIsDomainsVisible } = useVisibilityContext();
    const { editingKey, data, setData, originData, onPublish } = useDomainContext();


    const [{ data: domainData }, generateReport] = useAxios({
        method: "POST",
        url: `/domain/csv`,
        headers: {
            "content-type": "application/json",
        },
    }, {
        manual: true
    });

    const onAddRow = () => {
        const row = {
            key: data.length.toString(),
            siteId: '0',
            domain: 'www.example.com',
            desktop: 'true',
            mobile: 'true',
            date: '-',
            isNewlyAddedRow: true,
            beforeSave: true
        }
        const newData = [...data]
        newData.push(row)
        setData(newData)
        setIsNewAddedRow(true)
    }


    const goBack = () => {

        setIsDomainsVisible(false)
    }


    const onUndoAll = () => {
        undoAll()
        const modifiedOriginData = clearProps('isDeleted', null, [...originData])
        setData([...modifiedOriginData])
    }



    const onGenerateReport = () => {

        const source = [...dataSource].map(row => {
            return {
                siteId: row.siteId,
                domain: row.domain,
                desktop: row.desktop,
                mobile: row.mobile
            }
        })

        generateReport({
            data: {
                source
            }
        })
    }

    useEffect(() => {
        if (domainData) downloadReport(domainData)
    }, [domainData])


    return (

        <HeaderOperatioContainer>
            <StyledButtonsContainer>
                {getActions().length ? <Popconfirm title={getActions().length ? "You have unpublished changed" : 'Demo'} onConfirm={goBack}>
                    <UButton disabled={editingKey} icon={<RollbackOutlined />} title="Go Back" />
                </Popconfirm> : <UButton disabled={editingKey} onClick={goBack} icon={<RollbackOutlined />} title="Go Back" />
                }
                <UButton disabled={editingKey} onClick={onAddRow} icon={<PlusOutlined />} title="Add Row" />
                <Popconfirm title="Are you sure you want to undo all?" onConfirm={onUndoAll}>
                    <UButton disabled={editingKey} icon={<UndoOutlined />} title="Undo All" />
                </Popconfirm>
                <Popconfirm title="Are you sure you want to publish?" onConfirm={onPublish}>
                    <UButton disabled={editingKey} icon={<UploadOutlined />} title="Publish" />
                </Popconfirm>
                <UButton disabled={editingKey} icon={<FileTextOutlined />} title="Report" onClick={onGenerateReport} />
            </StyledButtonsContainer>
            <StyledSearchInput value={searchTerm} onChange={onSearch} />
        </HeaderOperatioContainer>

    )
}