import React, { useContext, createContext, useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { ACTION_TYPE, addAction, publish, deleteAction } from "../services/domain.service";
import { reOrderKeys } from "../services/table.service";
import { Form } from 'antd';
import { deleteFromArray } from '../utils/utils';

let originData = [];

const DomainContext = createContext();

function DomainProvider({ children }) {

    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [editedRow, setEditedRow] = useState(null)

    const [{ data: domainData }, getDomains] = useAxios({
        method: "GET",
        url: `/domain`,
        headers: {
            "content-type": "application/json",
        },
    });

    const [{ data: addDomainData }, addDomain] = useAxios({
        method: "POST",
        url: `/domain`,
        headers: {
            "content-type": "application/json",
        },
    }, {
        manual: true
    });

    const [{ data: updateDomainData }, updateDomain] = useAxios({
        method: "PUT",
        url: `/domain`,
        headers: {
            "content-type": "application/json",
        },
    }, {
        manual: true
    });

    useEffect(() => {
        if (domainData?.length) initDomainData(domainData)
    }, [domainData])

    const initDomainData = (domainData) => {
        originData = []
        const length = domainData.length
        for (let i = 0; i < length; i++) {
            originData.push({
                key: i.toString(),
                siteId: domainData[i].site_id,
                domain: domainData[i].domain,
                desktop: domainData[i].desktop.toString(),
                mobile: domainData[i].mobile.toString(),
                date: domainData[i].date ? new Date(domainData[i]?.date).toLocaleDateString("en-US") : '-',
                isNewlyAddedRow: false,
            });
        }
        setData([...originData])
    }

    const onPublish = async () => {
        await publish(removeDeletedRows)
        originData = [...data]
        reOrderKeys(null, originData, null)
        setData([...originData])
        getDomains()
    }

    const removeDeletedRows = deletedKeys => {
        //more efficent -> greater than originalData.length
        data.forEach(item => {
            if (item.isNewlyAddedRow) item.isNewlyAddedRow = false
        })
        if (!deletedKeys.length) return
        deletedKeys.forEach(key => {
            const idx = data.findIndex(item => item.key === key)
            data.splice(idx, 1)
        })
    }

    const edit = (record) => {
        setEditedRow(record)
        form.setFieldsValue({
            siteId: '',
            domain: '',
            desktop: '',
            mobile: '',
            date: '-',
            isNewlyAddedRow: false,
            isEdited: true,
            ...record,
        });
        setEditingKey(record.key);
    };

    const remove = async record => {
        const { isNewlyAddedRow, key } = record
        if (isNewlyAddedRow) {
            const deletedRecordIdx = data.findIndex(item => item.key === key)
            const newData = [...data]
            deleteFromArray(newData, key, 'key')
            reOrderKeys(data, newData, deletedRecordIdx)
            setData(newData)
            deleteAction(key)
        } else {
            addAction(ACTION_TYPE.DELETE, record, deleteDomain)
            record.isDeleted = true
            setData([...data]);
        }
    }

    const [{ data: deleteDomainData }, deleteDomain] = useAxios({
        method: "DELETE",
        url: `/domain`,
        headers: {
            "content-type": "application/json",
        }
    }, {
        manual: true
    });

    return (
        <DomainContext.Provider
            value={
                {
                    data,
                    setData,
                    originData,
                    onPublish,
                    form,
                    editedRow,
                    setEditedRow,
                    editingKey,
                    setEditingKey,
                    addDomain,
                    edit,
                    updateDomain,
                    deleteDomain,
                    remove
                }
            }>
            {children}
        </DomainContext.Provider>
    );
}

function useDomainContext() {
    const context = useContext(DomainContext);
    if (context === undefined) {
        throw new Error(`useDomainContext must be used within a TreeContext`);
    }
    return context;
}

export { DomainProvider, useDomainContext };
