import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import UButton from "Shared/Components/Button/UButton"
import { useDomainContext } from "../../context/DomainContext"
import { StyledInRowButtonsContainer } from "./style";
import { deleteFromArray } from '../../utils/utils';
import { reOrderKeys } from '../../services/table.service';
import { ACTION_TYPE, addAction, deleteAction } from '../../services/domain.service';
import useAxios from "axios-hooks";


export const DefaultOperations = ({ record }) => {

    const { editingKey, edit, remove } = useDomainContext()



    return (
        <StyledInRowButtonsContainer>
            <UButton disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />} title="Edit Row" />
            <UButton disabled={editingKey !== ''} onClick={() => remove(record)} icon={<DeleteOutlined />} title="Delete Row" />
        </StyledInRowButtonsContainer>
    )
}