import styled from "styled-components"
import { Form, Input, Select } from 'antd';


export const StyledSelect = styled(Select)`



.ant-select-selector {
    /* height: 42px !important; */
    background-color: transparent !important;
    border-color: #707070 !important;
    color: white !important;
    display: flex;
    align-items: center;
    border-radius: 7px !important;
}

.ant-select-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 6px;
    height: 100%;
    width: 40px;
    color: white;
    background-image: linear-gradient(to right, #360095, #4e0e7e);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border: 1px solid #707070;


    span {
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .anticon-down {
    background-color: lightpink;
    background-color: white;
    opacity: 0.5;
    color: black;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    }

}
`



export const StyledFormItem = styled(Form.Item)`
    
    input {
        background-color: transparent !important;

    }
    `




export const StyledInput = styled(Input)`
    background: transparent;
    color: white;
    border-color: #787878;
    border-radius: 7px;

    &:hover, &:focus {
        border-color: #787878;
        box-shadow: none
    }

    &::placeholder {
        opacity: .25
    }


`