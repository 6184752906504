import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Row, Layout, Dropdown } from "antd";
import { backgrounds } from "Shared/colors";
import styled from "styled-components";
import { useGlobal } from "GlobalContext/GlobalContext";
import { sendMessage } from "Shared/messages";
import {
  minimizeIcon,
  maximizeIcon,
  playButtonIcon,
} from "Shared/Icons/Icons.js";
import {
  InitialsSign,
  ChangeSize,
  StyledDivider,
  ArrowIcon,
  U1Heading,
} from "Main/Shared/Styles";
import { SwitchMenu } from "../SwitchDropdown/Button";
import IdeDropdown from "./IdeDropdown";
import { MenuButton } from "Main/Shared/MenuStyles";
import { useSwitch } from "Main/Context/SwitchContext";
import {
  IDE_ACTIONS,
  MESSAGES,
  PRODUCT,
  SCRIPTER_PREVIEW_MODE,
  STORAGE_NAMES,
} from "../consts";
import { Storage } from "Shared/Services/storage";
import { useVisibilityContext } from "Main/Context/ElementVisibilityContext";
import { UserDropdown } from "./UserDropdown";

const { Header } = Layout;

const StyledRow = styled(Row)`
  display: grid;
  height: 100%;

  .anticon.anticon-down {
    left: 170px;
    top: 7px !important;
    z-index: 5555;
  }

  .ant-dropdown-trigger {
    position: absolute;
    left: 70px;
    cursor: pointer;
    top: 7px;
    z-index: 1;
  }
`;

const StyledHeader = styled(Header)`
  height: 48px;
  display: flex;
  padding: 0px;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: ${backgrounds.PURPLE_GRADIENT};
  box-shadow: 0px 5px 11px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

const PlayButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: -10px;
    left: 296px;
    z-index: 5;
    border: none;
    background: none;
`;

const getinitials = (firstName, lastName) => `${firstName[0]}${lastName[0]}`;

const ScripterHeader = ({ }) => {
  const {
    user: { name, surname },
  } = useGlobal();

  const {
    ideAction,
    setIdeAction,
    isMinimized,
    toggleSize,
    setActiveProd,
    setIdeVisibility,
  } = useSwitch();
  const { setPublishVisible, publishTooltipVisibility, setPackageModalVisiblity } =
    useVisibilityContext();
  const menuBtn = useRef(null);
  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  useEffect(() => {
    const actionName = Storage.GetItem(STORAGE_NAMES.IDE_ACTION);
    actionName && setIdeAction(actionName);
  }, []);

  const preview = () => {
    Storage.SetItem(SCRIPTER_PREVIEW_MODE, "true");
    Storage.SetItem(STORAGE_NAMES.PRODUCT, PRODUCT.TOOLBAR);
    setActiveProd(PRODUCT.TOOLBAR);
    setIdeVisibility(false);
    sendMessage({
      type: MESSAGES.PRODUCT,
      data: PRODUCT.TOOLBAR,
      isIdeVisible: false,
      isMenuVisible: true,
    });
  };

  const activateIdeAction = () => {
    if (ideAction == IDE_ACTIONS.PUBLISH) {
      setPublishVisible(true);
    }
    else if (ideAction == IDE_ACTIONS.PACAKGE) {
      setPackageModalVisiblity(true);
    }
    else {
      preview();
    }
  };

  return (
    <StyledHeader>
      <StyledRow style={{ width: "27%" }}>
        <U1Heading
          style={{ position: "absolute", top: "-7px", left: "15px" }}
          key="brand"
        >
          u1
        </U1Heading>

        <StyledDivider
          style={{ position: "absolute", left: "50px", top: "10px" }}
        />

        <SwitchMenu />
      </StyledRow>

      <StyledRow style={{ width: "73%", background: "rgb(0,0,0, 0.3)" }}>
        <Tooltip
          visible={publishTooltipVisibility}
          placement="bottomLeft"
          title={"Published successfully"}
        >
          <PlayButton onClick={activateIdeAction}><img alt="play" src={playButtonIcon}/></PlayButton>
        </Tooltip>
        <Dropdown
          onVisibleChange={(visible) => setDropdownVisibility(visible)}
          trigger={["click"]}
          overlay={IdeDropdown()}
          placement="bottomCenter"
        >
          <MenuButton
            ismenuvisible={String(isDropdownVisible)}
            ref={menuBtn}
            style={{ position: "relative", left: "20px", width: "150px" }}
          >
            <span style={{ marginLeft: "20px" }}>{ideAction}</span>
          </MenuButton>
        </Dropdown>
        <ArrowIcon
          onClick={() => {
            menuBtn.current.click();
          }}
          style={{ top: "7px", left: "420px" }}
        />

        <UserDropdown>
          <InitialsSign
            style={{ left: "91%", top: "10px", position: "absolute" }}
          >
            {getinitials(name, surname)}
          </InitialsSign>
        </UserDropdown>

        <StyledDivider
          style={{ position: "absolute", left: "95%", top: "10px" }}
        />

        <ChangeSize
          style={{ position: "absolute", left: "97%", top: "15px" }}
          onClick={toggleSize}
          src={isMinimized ? maximizeIcon : minimizeIcon}
          alt="change size"
        />
      </StyledRow>
    </StyledHeader>
  );
};

export default ScripterHeader;
