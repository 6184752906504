import React from 'react'
import { Layout, Tree } from 'antd';
import { colors } from 'Shared/colors';
import styled from "styled-components";
import { useScripter } from '../../../Context/ScripterContext';
import TreeNodeIcon from "../Tree/TreeNodeIcon";

const { DirectoryTree } = Tree;


const { Content } = Layout;

const StyledContent = styled(Content)`
    padding:0 0 0 10px;
    background: ${colors.BLACK};
    color: white;
    z-index: 1;
    height: calc(100vh - 80px);
    overflow:auto;

    ::-webkit-scrollbar {
        width: 0.8rem !important;
      }
    
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
      }
    
      ::-webkit-scrollbar-thumb {
        background-color: transparent !important;
        outline: 0px solid slategrey !important;
        border-radius: 0px !important;
      }

      :hover{
        ::-webkit-scrollbar-thumb {
            background-color: ${colors.BLACK} !important;
            outline: 0px solid slategrey !important;
            border-radius: 0px !important;
          }
          ::-webkit-scrollbar-thumb:hover {
            background-color: ${colors.DARK60} !important;
            outline: 0px solid slategrey !important;
            border-radius: 0px !important;
          }
      }
`
const StyledTree = styled(DirectoryTree)`
    background: ${colors.BLACK};
    color: white;
    font-size: .7rem;
  
    .ant-tree-treenode {
      width: 12.5rem;
      margin-bottom: 8px;
    }

    .ant-tree-treenode:not(.ant-tree-treenode-selected){
        :before{
            background: ${colors.BLACK} !important;
        }
    }
    .ant-tree-treenode-selected{
        :before{
            background: ${colors.DARK60} !important;
        }
    }
    .ant-tree-treenode:not(.ant-tree-treenode-selected):hover{
        :before{
            background: ${colors.DARK70} !important;
        }
    }
    .ant-tree-list-holder-inner, .ant-tree-node-content-wrapper{
      display: flex;
      align-items: center;
    }
  
    .ant-tree-switcher {
      display: none; 
    }

    .ant-tree-title>span {
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: initial;
        position: absolute;
        width: 9rem;
        vertical-align: middle;
        text-overflow: ellipsis !important;
        top: 0rem;
    }
`


const SearchContent = ({ searchData, searchQuery }) => {

    const { SetSearchTreeNode, AdjustExpandedKeys, setSearchValue } = useScripter();

    return (
        <StyledContent>
            {searchData && searchData.length > 0 &&
                <StyledTree
                    onSelect={(keys, { node }) => {
                        SetSearchTreeNode(node);
                        AdjustExpandedKeys(node);
                        setSearchValue(searchQuery);
                    }}
                    treeData={searchData}
                    icon={(props) => TreeNodeIcon(props)}
                />
            }
        </StyledContent>
    )
}

export default SearchContent;