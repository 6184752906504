import styled from "styled-components";
import { Table } from "antd";
import { colors } from "Shared/colors";

export const StyledTable = styled(Table)`
    margin: 0 20px;
    height: 100% ;
    
    table {
      padding: 15px;
      position: absolute;
    }
    
    .ant-table-thead > tr > th,
    tbody tr {
      background-color: ${colors.BLACK};
      color: ${colors.WHITE};
    }
    
    .ant-table-container {
      background-color: ${colors.BLACK} !important;
    } 
    
    th,
    .ant-table-container,
    tbody td {
        border: none !important;
      }

    tbody td {
        border-top: 1px solid ${colors.DARK_GRAY} !important;
        padding: 16px !important;
    }

    .ant-table-thead > tr > th {
        border-bottom: 3px solid ${colors.DARK_GRAY} !important;
    }

    td.ant-table-column-sort {
      background: unset;
    }
  
     th:hover, 
     tr:hover > td {
        background-color: ${colors.LIGHT_GRAY} !important;
    }

  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
    width: 200px;
    display: flex;

     & > * {
       margin-right: 6px ;
     }
  }

  /* .newly-added input, */
  .newly-added:hover,
  .newly-added{
    background-color: rgba(0,255,0,.25)!important
  }

.deleted {
  background-color: rgba(255,0,0,.25)!important
}

.edited{
  background-color:rgba(255,255,0, .25);
}
`

export const DomainContainer = styled.div`
overflow: auto;
height: 100%;
`