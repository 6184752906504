import React, { useContext, createContext, useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { apiClient } from "Shared/apiClient";
import { toastError, toastSuccess } from "Shared/utils";

const AuthContext = createContext();

const VALIDATION_ERROR_TYPES = {
  LOGIN_LOCKED: "LoginLocked",
  PASSWORD_EXPIRED: "PasswordExpired",
  INCORRECT_CREDENTIALS: "IncorrectCredentials",
  VALIDATION_ERROR: "ValidationError"
}

const LOGIN_ERROR_MESSAGES = {
  WRONG_CREDS: "Wrong credentials",
  LOGIN_LOCKED: "Login locked for 5 minutes",
  LOGIN_BLOCKED: "Login blocked! You haven't changed your password for 90 days. Please contact to the administrator to update your password",
  VALIDATION_ERROR: "Validation server error! Try again later"
}

const LOGIN_STATE = {
  LOGGED_IN: "logged_in",
  LOGGED_OUT: "logged_out",
};
export const LS_LOGGED_IN = "LOGGED_IN";
export const LS_ACCESS_TOKEN = "ACCESS_TOKEN";
function AuthProvider({ children }) {
  const [loginState, setLoginState] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isLoginError, setIsLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isTwoFactorVerificationEnabled, setIsTwoFactorVerificationEnabled] = useState(false);
  const [isTwoFactorVerificationPageEnabled, setIsTwoFactorVerificationPageEnabled] = useState(false);

  const verifyStorage = () => {
    const loginState = localStorage.getItem(LS_LOGGED_IN);
    setLoginState(
      loginState ? LOGIN_STATE.LOGGED_IN : LOGIN_STATE.LOGGED_OUT
    );
  };

  const loginProcess = async (email, password) => {
    try {
      setIsLoginError(false);

      let res = await apiClient("/account/user-validation", { body: { email: email, password: password }});

      if(res.data.is_valid) {
        let res = await apiClient("/account/get-2fa-status");

        if(res.data.is_two_factor_enabled) {
          sendVerificationCode(email);
        }
        else {
          login(email, password);
        }
      }
      else {
        setIsLoginError(true);
        switch(res.data.message) {
          case VALIDATION_ERROR_TYPES.PASSWORD_EXPIRED:
            setLoginErrorMessage(LOGIN_ERROR_MESSAGES.LOGIN_BLOCKED);
            break;
          case VALIDATION_ERROR_TYPES.LOGIN_LOCKED:
            setLoginErrorMessage(LOGIN_ERROR_MESSAGES.LOGIN_LOCKED);
            break;
          case VALIDATION_ERROR_TYPES.INCORRECT_CREDENTIALS:
            setLoginErrorMessage(LOGIN_ERROR_MESSAGES.WRONG_CREDS);
            break;
          case VALIDATION_ERROR_TYPES.VALIDATION_ERROR:
            setLoginErrorMessage(LOGIN_ERROR_MESSAGES.VALIDATION_ERROR)
            break;
        }
      }
    } catch (err) {
      setIsLoginError(true);
      setLoginErrorMessage(LOGIN_ERROR_MESSAGES.WRONG_CREDS);
    }
  };

  const login = async (email, password) => {
    try {
      let res = await apiClient("/account/login", {
        body: { email: email, password: password },
      });
      if(res.data.is_success) {
        setLoginState(LOGIN_STATE.LOGGED_IN);
        localStorage.setItem(LS_LOGGED_IN, true);
        localStorage.setItem(LS_ACCESS_TOKEN, res.data.access_token);
        navigate("/main/default");
        setIsLoginError(false);
      }
      else {
        toastError(
          res.data.message
        )
        setIsTwoFactorVerificationPageEnabled(false);
      }
    } catch (err) {
      setIsTwoFactorVerificationPageEnabled(false);
      setIsLoginError(true);
      setLoginErrorMessage(LOGIN_ERROR_MESSAGES.WRONG_CREDS);
    }
  };

  const sendVerificationCode = async (email) => {
    let res = await apiClient("/twofactorauthentication/send-code", { body: { email: email }});
    if(!res.data.is_success) {
      toastError(
        res.data.message
      )
    }
    else {
      setIsTwoFactorVerificationEnabled(true);
      setIsTwoFactorVerificationPageEnabled(true);
      toastSuccess(
        'Code was send successfully'
      )
    }
  }

  const verifyUser = async (verificationCode, email, password) => {
    try {
      let res = await apiClient("/twofactorauthentication/verify", {
        body: { email: email, code: verificationCode }
      })
      if(res.data.is_success) {
        login(email, password);
      }
      else
      {
        toastError(
          res.data.message
        )
      }
    }
    catch (err) {
      setIsTwoFactorVerificationPageEnabled(false);
    }
  }

  const logout = () => {
    setLoginState(LOGIN_STATE.LOGGED_OUT);
    localStorage.removeItem(LS_LOGGED_IN);
    localStorage.removeItem(LS_ACCESS_TOKEN);
    setIsTwoFactorVerificationPageEnabled(false);
    navigate("/login");
  };
  useEffect(() => {
    verifyStorage();
  }, []);
  useEffect(() => {
    loginState !== null && setIsLoggedIn(loginState === LOGIN_STATE.LOGGED_IN);
    if (loginState) {
      loginState === LOGIN_STATE.LOGGED_OUT && navigate("/login");
      loginState === LOGIN_STATE.LOGGED_IN && navigate("/main/default");
    }
  }, [loginState]);
  return (
    <AuthContext.Provider
      value={{
        loginProcess,
        logout,
        verifyUser,
        isLoginError,
        isLoggedIn,
        isTwoFactorVerificationPageEnabled,
        isTwoFactorVerificationEnabled,
        sendVerificationCode,
        loginErrorMessage
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
export { AuthProvider, useAuth, LOGIN_STATE as loginStatesConsts };