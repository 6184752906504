import React, { useContext, createContext, useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { useAuth } from "Auth/AuthContext";

const GlobalContext = createContext();

export const SITE_SETTINGS_CONSTS = {
  LANGUAGES: "languages",
  SITE_ID: "site_id",
  DEPRECATED_DYNAMIC: "deprecated_dynamic",
  LANDMARKS: "landmarks",
  COMPONENTS: "components",
  HIERARCHIES: "hierarchies",
  PROPERTIES: "properties",
  DEVICES: "devices",
  BEHAVIORS: "behaviors",
  ROLES: "roles",
  WIDGETS: "widgets",
};

const isMultipleProperties = (constant) => {
  return (
    constant === SITE_SETTINGS_CONSTS.BEHAVIORS ||
    constant === SITE_SETTINGS_CONSTS.WIDGETS
  );
};

//var siteSettingsRetries = 0;

function GlobalProvider({ children }) {
  const [url, setUrl] = useState(null);
  const [user, setUser] = useState({ name: "", surname: "" });
  const [userInterval, setUserInterval] = useState(null);
  const [siteID, setSiteID] = useState(null);

  const { isLoggedIn, logout } = useAuth();
  const [{ data: siteSettings, error: errorsSiteSettings }, fetchSiteSettings] =
    useAxios(
      {
        method: "POST",
        url: `/site/get-site-settings`,
        headers: {
          "content-type": "application/json",
        },
      },
      { manual: true }
    );

  const [{ data: userData, error: userError }, fetchUser] = useAxios(
    {
      method: "GET",
      url: `/account/user`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        var url = await getCurrentUrl();
        if (url) {
          fetchSiteSettings({ data: { url: url } });
          setUrl(url);
        }

        fetchUser();

        let interval = setInterval(() => {
          fetchUser();
        }, 1000 * 60 * 5);
        setUserInterval(interval);
      }
    })();
  }, [fetchSiteSettings, fetchUser, isLoggedIn]);

  useEffect(() => {
    if (userError) {
      if (userInterval) {
        clearInterval(userInterval);
      }
      logout();
    }
    if (userData) {
      const { name, surname } = userData
      setUser({ name, surname });
    }
  }, [logout, userData, userError, userInterval]);

  useEffect(() => {
    if (siteSettings) {
      setSiteID(siteSettings.site_id);
    }
  }, [siteSettings]);

  return (
    <GlobalContext.Provider
      value={{
        url,
        siteSettings,
        user,
        siteID,
        fetchSiteSettings,
        errorsSiteSettings,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

function useGlobal() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error(`useGlobal must be used within a GlobalProvider`);
  }
  return context;
}

export { GlobalProvider, useGlobal };
