import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import SyntaxHighlighter, { createElement } from "react-syntax-highlighter";
import { hybrid } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { validatorsInfo } from "Main/Views/Preview/validatorsInfo";
import { Content } from "antd/lib/layout/layout";
import { CopyOutlined } from "@ant-design/icons";
import CopyHighlightSelector from "./CopyHighlightSelector";
import { priorityColors } from "../colors";
import {
  sendHighlightMessage,
  sendScripterMessage,
  recieveMessage,
} from "Shared/messages";

import ToggleSwitch from "../ToggleSwitch";

const SelectorsContainer = styled.div`
  margin: 0;
  overflow-x: auto;
  min-height: 20vh;
  max-height: 20vh;
  background-color: ${hybrid.hljs.background};
  padding: 0.25rem 0;
`;

const Container = styled.div`
  height: calc(100vh - 96px - 0.15rem);
  margin: 0.15rem 0.3rem 0rem 0rem;
  overflow: auto;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;

  & > :not(${SelectorsContainer}) {
    padding: 0 15px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #404040;
  }
`;

const headingStyle = css`
  color: white;
  font-size: 14px;
  font-family: Segoe UI;
  margin-bottom: 0;
  line-height: 18px;
  word-spacing: 0px;
`;

const Heading = styled.h2`
  ${headingStyle}
  font-weight: 400;
`;

const TagsContainer = styled.section`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  width: 32px;

  font-size: 10px;
  font-weight: 700;

  border-radius: 5px;
  background-color: ${{}};
  color: black;
`;

const LevelTag = styled(Tag)`
  width: 47px;
  background-color: #ed213a;
  color: white;
  font-size: 12px;
  font-weight: 800;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const SubHeading = styled.h3`
  ${headingStyle}
  font-weight: 500;
`;

const Paragraph = styled.p`
  color: white;
  opacity: 0.75;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 0;
`;

const Block = styled.blockquote`
  font-size: 11px;
  line-height: 15px;
  background-color: #2e2e2e;
  border-radius: 10px;
  padding: 10px 20px;
`;

const IssuesContainer = styled.section`
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: 600;
  gap: 2px;
`;

const IssuesCounter = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  color: ${({ severity }) => priorityColors[severity]};
  margin: -2px;
`;

const getTextResult = (body) => (heading) =>
  body.match(new RegExp(`(\?\<\=${heading}</h2>\n\n<p>)\.\*(\?\=</p>)`, "i"));

const headings = [
  "The Algorithm, In Simple Terms",
  "Why Is It Important\\?",
  "How To Fix The Problem",
];

const ErrorDetails = ({ activeError }) => {
  const { title, user_impact_text, wcag_level, wcag_guidelines, body } =
    validatorsInfo[activeError.Code];

  const [meaningTextResult, blockTextResult, howToFixTextResult] = headings.map(
    getTextResult(body)
  );
  const [errors, setErrors] = useState(activeError.Elements);

  const [activeHighlightSelector, setActiveHighlightSelector] =
    useState(undefined);

  const [showInvisible, setShowInvisible] = useState(true);
  useEffect(() => {
    const id = new Date().getTime();
    sendScripterMessage({
      type: "get_selectors_for_err_code",
      errCode: activeError.Code,
      id,
    });
    recieveMessage(`selector_ready_${id}`, (data) => {
      const res = data.result;
      if (res.errCode == activeError.Code) {
        try {
          activeError.Elements.find((e) => e.Id == res.Id).ElementSelector =
            res.ElementSelector;
          setErrors([...activeError.Elements]);
        } catch (e) {
          //console.log(e);
        }
      }
    });
  }, [activeError]);

  useEffect(
    () => () =>
      window.parent.postMessage({ type: "hide_error_element_highlight" }, "*"),
    []
  );

  return (
    <Container>
      <Heading>{title}</Heading>
      <TagsContainer>
        <LevelTag>{wcag_level[0]}</LevelTag>
        {wcag_guidelines.map(({ title }, i) => (
          <Tag key={i}>{title}</Tag>
        ))}
      </TagsContainer>
      {errors && (
        <>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
            `}
          >
            <IssuesContainer>
              <IssuesCounter severity={user_impact_text}>
                {errors.length > 99 ? "99+" : errors.length}
              </IssuesCounter>
              Issue(s) Were Found
            </IssuesContainer>
            <ToggleSwitch
              checked={showInvisible}
              onClick={() =>
                setShowInvisible((showInvisible) => !showInvisible)
              }
              checkedChildren="All"
              unCheckedChildren="Visible Only"
            />
          </div>
          <SelectorsContainer>
            {errors
              .filter(({ IsVisible }) => showInvisible || IsVisible)
              .map(({ ElementSelector, Id, IsVisible }) =>
                ElementSelector ? (
                  <SyntaxHighlighter
                    key={Id}
                    language="css"
                    style={hybrid}
                    wrapLongLines={true}
                    PreTag={CopyHighlightSelector(
                      ElementSelector,
                      activeHighlightSelector,
                      setActiveHighlightSelector,
                      IsVisible
                    )}
                  >
                    {ElementSelector}
                  </SyntaxHighlighter>
                ) : (
                  <></>
                )
              )}
          </SelectorsContainer>
        </>
      )}
      {meaningTextResult && (
        <Section>
          <SubHeading>Meaning</SubHeading>
          <Paragraph
            dangerouslySetInnerHTML={{ __html: meaningTextResult[0] }}
          />
          {blockTextResult && (
            <Block dangerouslySetInnerHTML={{ __html: blockTextResult[0] }} />
          )}
        </Section>
      )}
      {howToFixTextResult && (
        <Section>
          <SubHeading style={{ textAlign: "right" }}>How To Fix</SubHeading>
          <Paragraph
            dangerouslySetInnerHTML={{ __html: howToFixTextResult[0] }}
          />
        </Section>
      )}
    </Container>
  );
};

export default ErrorDetails;
