import React, { useEffect } from "react";
import styled from "styled-components";
import { useGlobal } from "GlobalContext/GlobalContext";
import { minimizeIcon, maximizeIcon } from "Shared/Icons/Icons.js";
import { PRODUCT, SCRIPTER_PREVIEW_MODE } from "Main/Views/consts";
import {
  InitialsSign,
  ChangeSize,
  StyledDivider,
  U1Heading,
} from "Main/Shared/Styles";
import { backgrounds } from "Shared/colors";
import { SwitchMenu } from "Main/Views/SwitchDropdown/Button";
import { useSwitch } from "Main/Context/SwitchContext";

const Header = styled.header`
  height: 48px;
  display: flex;
  padding: 0 13px;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: ${backgrounds.PURPLE_GRADIENT};
  box-shadow: 0px 5px 11px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;

  .anticon.anticon-down {
    left: 154px;
    top: 7px;
  }

  ${(props) => (props.isFrontend ? `width: 237px` : `width: 100%`)}
`;

const RightContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const getinitials = (firstName, lastName) => `${firstName[0]}${lastName[0]}`;

const PreviewHeader = () => {
  const {
    user: { name, surname },
  } = useGlobal();

  useEffect(() => {
    document.body.style.backgroundColor = "transparent";
  }, []);

  const { activeProd, isMinimized, toggleSize } = useSwitch();

  return (
    <Header isFrontend={activeProd == PRODUCT.FRONTEND}>
      <U1Heading>
        <span style={{ fontWeight: 500 }}>u1</span>
        <StyledDivider style={{ marginLeft: "5px" }} />
      </U1Heading>

      <SwitchMenu />

      <RightContainer>
        <StyledDivider />
        <InitialsSign>{getinitials(name, surname)}</InitialsSign>

        {activeProd != PRODUCT.FRONTEND && (
          <>
            <StyledDivider />
            <ChangeSize
              style={{ marginTop: "5px" }}
              onClick={toggleSize}
              src={isMinimized ? maximizeIcon : minimizeIcon}
              alt="change size"
            />
          </>
        )}
      </RightContainer>
    </Header>
  );
};

export default PreviewHeader;
