import React, { useEffect, useState } from 'react';
import { useSwitch } from 'Main/Context/SwitchContext';
import { StyledMenu, MenuItem, StyledSubMenu } from 'Main/Shared/MenuStyles';
import { Storage } from 'Shared/Services/storage';
import { IDE_ACTIONS, PROFILES, STORAGE_NAMES } from '../consts';

const DropDownTitle = () => {
    return <span style={{ fontSize: '12px' }}>{IDE_ACTIONS.PREVIEW}</span>;
};

const IdeDropdown = () => {
    const { ideAction, setIdeAction } = useSwitch();
    const [selectedProfile, setSelectedProfile] = useState(Storage.GetItem(STORAGE_NAMES.PROFILE));
    useEffect(() => {
        !ideAction && setIdeAction(IDE_ACTIONS.PREVIEW);
    }, [ideAction]);

    useEffect(() => {
        !selectedProfile && setSelectedProfile(PROFILES.SCREEN_READER.key);
    }, [selectedProfile]);

    const updateIDEAction = (action, profile) => {
        Storage.SetItem(STORAGE_NAMES.IDE_ACTION, action);
        setIdeAction(action);

        profile && updateSelectedProfile(profile);
    };
    const updateSelectedProfile = profile => {
        Storage.SetItem(STORAGE_NAMES.PROFILE, profile);
        setSelectedProfile(profile);
    };

    return (
        <StyledMenu mode="vertical">
            <StyledSubMenu key="sub" title={<DropDownTitle />} activeItem={ideAction == IDE_ACTIONS.PREVIEW}>
                {Object.values(PROFILES).map(profile => {
                    return (
                        <MenuItem key={profile.key} activeItem={selectedProfile === profile.key}>
                            <a target="_blank" rel="noopener noreferrer" onClick={() => updateIDEAction(IDE_ACTIONS.PREVIEW, profile.key)}>
                                {profile.name}
                            </a>
                        </MenuItem>
                    );
                })}
            </StyledSubMenu>
            <MenuItem activeItem={ideAction === IDE_ACTIONS.PUBLISH}>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    key={IDE_ACTIONS.PUBLISH}
                    onClick={() => updateIDEAction(IDE_ACTIONS.PUBLISH)}
                >
                    {IDE_ACTIONS.PUBLISH}
                </a>
            </MenuItem>
            <MenuItem activeItem={ideAction === IDE_ACTIONS.PACKAGE}>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    key={IDE_ACTIONS.PACKAGE}
                    onClick={() => updateIDEAction(IDE_ACTIONS.PACKAGE)}
                >
                    {IDE_ACTIONS.PACKAGE}
                </a>
            </MenuItem>
        </StyledMenu>
    );
};

export default IdeDropdown;
