const booleanValidation = (_, value) => {
    if (value === 'true' || value === 'false') return Promise.resolve()
    return Promise.reject('Must be true or false')
}

const numberValidation = (_, value) => {
    if (!isNaN(value)) return Promise.resolve()
    return Promise.reject('Must be a number')
}

export const RULES = {
    siteId: {
        validator: numberValidation
    },
    mobile: {
        validator: booleanValidation
    },
    desktop: {
        validator: booleanValidation
    }
}


