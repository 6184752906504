import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: transparent linear-gradient(135deg, #360095 0%, #4e0e7e 100%) 0%
    0% no-repeat padding-box;
  border: unset;
  height: unset;
  color: white;
  border-radius: 6px;

  
  &:hover,
  &:active,
  &:focus {
    color: white;
    background: transparent linear-gradient(135deg, #360095 0%, #4e0e7e 100%) 0%
      0% no-repeat padding-box;
  }
  &:disabled {
    opacity: 0.78;
    color: #a8a4a4;
    background: transparent linear-gradient(135deg, #360095 0%, #4e0e7e 100%) 0%
      0% no-repeat padding-box;
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    opacity: 0.78;
    color: #a8a4a4;
    background: transparent linear-gradient(135deg, #360095 0%, #4e0e7e 100%) 0%
      0% no-repeat padding-box;
  }
`;

export default function UButton({ txt, padding, center, ...props }) {
  return <StyledButton center={center} padding={padding} {...props}>{txt}</StyledButton>;
}
