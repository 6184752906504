import { Menu, Dropdown } from 'antd';
import { useAuth } from 'Auth/AuthContext';
import { useVisibilityContext } from 'Main/Context/ElementVisibilityContext';
import React from "react";
export const UserDropdown = ({ children }) => {
    const { setIsDomainsVisible } = useVisibilityContext();
    const { logout, userRole } = useAuth()
    const menu = (
        <Menu>
            <Menu.Item onClick={() => { setIsDomainsVisible(true) }}>
                <div>
                    Domains
                </div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={logout}>
                    Logout
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            {children}
        </Dropdown>
    )
}