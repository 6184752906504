import React from "react";
import { Tabs } from "antd";
import { backgrounds, colors } from "Shared/colors";
import styled from "styled-components";
import { useScripter } from "Main/Context/ScripterContext";
import Editor from "./Editor";
import TabTitle from "./TabTitle";
import TabCloseIcon from "./TabCloseIcon";
import { FILE_EXTENSION } from "Main/Views/consts";
import { jsIcon, htmlIcon } from "Shared/Icons/Icons";
import { TITLES } from "Shared/consts";
const { TabPane } = Tabs;

const StyledTabPane = styled(TabPane)`
  background: ${colors.DARK90} !important;
  height: calc(100vh - 80px);
`;

const StyledTabs = styled(Tabs)`
  color: white !important;

  .ant-tabs-nav {
    margin: 0px !important;
  }
  .ant-tabs-nav-wrap {
    background: ${backgrounds.DARK_PURPLE_GRADIENT} !important;
    box-shadow: 3px 12px 36px -23px rgb(0 0 0) inset;
  }
  .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab-remove {
    color: ${colors.WHITE};
  }
  .ant-tabs-tab:hover .ant-tabs-tab-remove {
    color: white;
  }
  .ant-tabs-tab-active .ant-tabs-tab-remove {
    color: white;
  }
  .ant-tabs-tab {
    background: rgb(0, 0, 0, 0.2) !important;
    color: white !important;
    border: none !important;
    margin-left: 0px !important;
    box-shadow: inset -29px 0px 25px -15px rgb(0 0 0 / 20%);
  }
  .ant-tabs-tab-active {
    background: ${colors.DARK90} !important;
    color: white;
  }
  .ant-tabs-tab-disabled {
    display: none;
  }
  .hide-tab {
    visibility: hidden;
    width: 1;
  }
  .monaco-editor,
  .monaco-editor-background,
  .monaco-editor .inputarea.ime-input,
  .monaco-editor .margin {
    background: ${colors.DARK90} !important;
  }
  .minimap-decorations-layer {
    background: rgb(0, 0, 0, 0.4) !important;
  }
`;

const TabIcon = styled.img`
  margin-right: 5px;
`;

const TabControl = () => {
  const { setActiveTab, tabs, activeTab, removeTab, isCommonVisible, common } = useScripter();
  const isHtmlFile = (title) =>
    typeof title == "string" && title.includes(FILE_EXTENSION.HTML);

  return (
    tabs && (
      <StyledTabs
        hideAdd
        type="editable-card"
        onChange={setActiveTab}
        activeKey={activeTab?.key}
        onEdit={(targetKey, action) =>
          action === "remove" && removeTab(targetKey)
        }
      >
        {tabs &&
          tabs.map((pane) => (
            <StyledTabPane className={pane.key === common && !isCommonVisible ? 'hide-tab' : ''}
              tab={
                <>
                  <TabIcon src={isHtmlFile(pane.title) ? htmlIcon : jsIcon} />
                  <TabTitle
                    title={pane.title}
                    errorDetected={pane.errorDetected}
                    isActive={activeTab && activeTab.key == pane.key}
                  />
                </>
              }
              closeIcon={<TabCloseIcon changeDetected={pane.changeDetected} />}
              disabled={pane.title === `${TITLES.COMMON}.js` && !isCommonVisible ? true : false}
              key={pane.key}
              closable={true}
            >
              <Editor thisTab={pane} activeTabKey={activeTab?.key} />
            </StyledTabPane>
          ))}
      </StyledTabs>
    )
  );
};

export default TabControl;
