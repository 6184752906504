import React from 'react';
import { Form } from 'antd';
import { RULES } from '../../utils/rules';
import { StyledSelect, StyledInput, StyledFormItem } from './style'



export const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    getRowsData,
    ...restProps
}) => {
    let inputNode = inputType === 'select' ? <StyledSelect>
        <StyledSelect.Option value="true">true</StyledSelect.Option>
        <StyledSelect.Option value="false">false</StyledSelect.Option>
    </StyledSelect> : <StyledInput />;

    const domainValidator = (rowsData, key) => {
        return {
            validator: (_, value) => {
                if (!value) {
                    return Promise.reject('Required')
                }
                const validDomainPattern = new RegExp(/^(?!http|https|https:\/\/|http:\/\/)(^[?a-zA-Z0-9-.\+]{2,256}\.[a-z]{2,4}.*(?<!ab)$\b)/)
                if (!validDomainPattern.test(value)) {
                    return Promise.reject('Must be valid domain')
                }
                const isDuplicate = rowsData.findIndex(row => key !== row.key && row.domain === value) !== -1
                if (!isDuplicate) {
                    return Promise.resolve()
                }
                return Promise.reject('Domain must be unique')
            }
        }
    }


    return (
        <td {...restProps}>
            {editing ? (
                <StyledFormItem
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required`,
                        },
                        dataIndex === 'domain' ? domainValidator(getRowsData(), record.key) : RULES[dataIndex]
                    ]}
                >
                    {inputNode}
                </StyledFormItem>
            ) : (
                children
            )}
        </td>
    );
};
