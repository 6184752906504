import React, { useEffect } from "react";
import { Tabs, Layout } from "antd";
import { backgrounds, colors } from "Shared/colors";
import styled from "styled-components";
import Tree from "./Tree/Tree";
import Search from "./Search/Search";
import { useScripter } from "../../Context/ScripterContext";
import { explorerIcon, searchIcon } from "Shared/Icons/Icons.js";
import { useVisibilityContext } from "Main/Context/ElementVisibilityContext";

const { TabPane } = Tabs;

const { Sider } = Layout;
const StyledSider = styled(Sider)`
  background: ${colors.BLACK};
  height: 100%;
  color: white;
  .ant-layout-sider-zero-width-trigger {
    top: 0;
    right: ${(props) => props.rightposition}px;
    height: 43px;
    width: 43px;
    background: ${colors.BLACK};
    border-radius: 0;
    color: ${(props) => props.sidebarcolor};
  }

  .ant-tabs-content-holder{
    border: none;
  }

  .ant-tabs-ink-bar{
    background: ${colors.TRANSPARENT}
  }
`;

const StyledTabs = styled(Tabs)`
  background: ${backgrounds.PURPLE_GRADIENT} !important;
  color: white !important;
  
 .ant-tabs-tabpane{
   padding: 0px !important;
 }

 .ant-tabs-tab {
    padding: 10px 8px !important;
    margin: 0px !important;
    display: ${({ hide }) => hide ? 'none' : 'flex'};
 }

 .ant-tabs-tab-active {
    background: ${colors.BLACK} !important;
    color: white;
  }

  `;

const SiderIcon = styled.img`
    left: 12px;
    position: relative;
`;

const ScripterSider = () => {

  const { sideBarCollapsed, setSideBarCollapsed, sideBarColor, siderActiveTab, setSiderActiveTab, setResizeFlag } = useScripter();
  const { isDomainsVisible } = useVisibilityContext();

  useEffect(() => {
    if (isDomainsVisible) {
      setSideBarCollapsed(true)
    } else setSideBarCollapsed(false)
  }, [isDomainsVisible])

  const handleSideBar = () => {
    setSideBarCollapsed(!sideBarCollapsed);
    setResizeFlag(true);
  }


  return (
    <StyledSider
      collapsible
      onCollapse={setSideBarCollapsed}
      collapsed={sideBarCollapsed}
      defaultCollapsed={sideBarCollapsed}
      collapsedWidth="49px"
      width="270px"
      height="100%"
      trigger={null}
      rightposition={-43}
      sidebarcolor={sideBarColor}
      bgcolor={colors.BLACK}
    >
      <StyledTabs
        hide={isDomainsVisible}
        onChange={(activeKey) => setSiderActiveTab(activeKey)}
        tabBarGutter={5}
        defaultActiveKey="tree"
        tabPosition={"left"}>
        <TabPane
          disabled={isDomainsVisible}
          tab={
            <div onClick={() => siderActiveTab == "tree" && handleSideBar()}>
              <SiderIcon src={explorerIcon} />
            </div>
          }
          key="tree"
        >
          <Tree />
        </TabPane>

        <TabPane
          disabled={isDomainsVisible}
          tab={
            <div onClick={() => siderActiveTab == "search" && handleSideBar()}>
              <SiderIcon src={searchIcon} />
            </div>
          }
          key="search"
        >
          <Search />
        </TabPane>
      </StyledTabs>
    </StyledSider>
  );
};

export default ScripterSider;
