import React, { useContext, createContext, useState } from "react";


const VisibilityContext = createContext();

function VisibilityProvider({ children }) {
    const [isPublishModalVisible, setPublishVisible] = useState(false);
    const [publishTooltipVisibility, setPublishTooltipVisibility] = useState(false);
    const [reportModalVisibility, setReportModalVisibility] = useState(false);
    const [packageModalVisiblity, setPackageModalVisiblity] = useState(false);
    const [isDomainsVisible, setIsDomainsVisible] = useState(false)
    return (
        <VisibilityContext.Provider
            value={
                {
                    isPublishModalVisible,
                    setPublishVisible,
                    publishTooltipVisibility,
                    setPublishTooltipVisibility,
                    reportModalVisibility,
                    setReportModalVisibility,
                    packageModalVisiblity,
                    setPackageModalVisiblity,
                    isDomainsVisible,
                    setIsDomainsVisible
                }
            }>
            {children}
        </VisibilityContext.Provider>
    );
}

function useVisibilityContext() {
    const context = useContext(VisibilityContext);
    if (context === undefined) {
        throw new Error(`useVisibilityContext must be used within a TreeContext`);
    }
    return context;
}

export { VisibilityProvider, useVisibilityContext };
