export const colors = {
    DARK100: '#000',
    DARK90: '#111',
    DARK80: '#222',
    DARK70: '#333',
    DARK60: '#444',
    BLACK: '#000000',
    WHITE: '#fff',
    GRAY: '#2D2D2D',
    GREY_700: "#191919",
    GREEN_SUCCESS: "#33C211",
    RED_ERROR: "#940000",
    YELLOW_WARNING: '#FF9F40',
    LIGHT_GRAY: '#5e5e5e',
    DARK_BLUE: '#274c7d',
    BUTTON_GRAY: "#535353",
    BUTTON_BLUE: "#23408D",
    LIGHT_GREY: "#C9C9C9",
    DARK_GRAY: '#2C2C2C',
    EXTRA_DARK_GRAY: '#1E1E1E',
    LIGHT_MID_GREY: "#999",
    MID_GREY: "#777",
    LIGHT_GREEN: "#BEF49C",
    LIGHT_RED: "#FFBABA",
    RED: "#c62828",
    DARK_RED: "#8e0000",
    LIGHT_BLUE: "#F1F2F6",
    LIGHT_ORANGE: "#ffe7ba",
    MONACO_DARK: "#1E1E1E",
    ORANGE: "#FF5700",
    BLUE: "#5977AD",
    RED2: "#FF0900",
    SCRIPTER_PREVIEW_BLUE: "#2f5d8c",
    SCRIPTER_PREVIEW_LIGHT_BLUE: "#2F5D8C",
    SCRIPTER_PREVIEW_BLACK: "#0C0C0C",
    TRANSPARENT: "#0000",
    DARK_PURPLE_OPACITY: "#1e1e1e80"
}

export const backgrounds = {
    NOT_FOUND: 'linear-gradient(135deg, #ff5f52 0%, #8e0000 100%)',
    FOUND: 'linear-gradient(135deg, #8aa6df 0%, #274c7d 100%)',
    FOUND_MULTIPLE: 'linear-gradient(135deg, #d07dff 0%, #6a1b9a 100%)',
    BROKEN: 'linear-gradient(135deg, #F3AA4A 0%, #FF9100 100%)',
    GREY: 'linear-gradient(135deg, rgb(152 152 152) 0%, rgb(76 75 75) 100%)',
    PURPLE_GRADIENT: 'linear-gradient(110deg, rgba(78,14,112,1) 0%, rgba(54,0,149,1) 50%, rgba(54,0,149,1) 100%)',
    DARK_PURPLE_GRADIENT: 'linear-gradient(90deg, rgba(23,5,66,1) 0%, rgba(45,13,103,1) 12%, rgba(53,16,116,1) 35%, rgba(40,4,62,1) 94%, rgba(41,4,66,1) 97%)',
    PURPLE_90DEG: 'linear-gradient(90deg, rgba(119,51,170,1) 0%, rgba(86,35,122,1) 0%, rgba(54,0,149,1) 35%)'
}