import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { sendScripterMessage, recieveMessage } from "Shared/messages";
import PreviewHeader from "./Header/PreviewHeader";
import PreviewContent from "./Main/PreviewContent";
import { validatorsInfo } from "./validatorsInfo";
import { Routes, Route } from "react-router-dom";
import ErrorDetailsContainer from "./Main/ErrorDetails/ErrorDetailsContainer";
import { PreviewProvider } from "Main/Context/PreviewContext";
import { Storage } from "Shared/Services/storage";
import { SCRIPTER_PREVIEW_MODE, STORAGE_NAMES } from "../consts";
import { SCRIPTER_MESSAGES } from "../../../Shared/consts";

const ScripterPreview = () => {
  const [validators, setValidators] = useState(null);
  const [
    { data: scriptsData, loading: scriptsLoading, error: scriptsError },
    fetchScripts,
  ] = useAxios(
    {
      method: "POST",
      url: `/scripter/scripts`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [
    {
      data: localizationData,
      loading: localizationLoading,
      error: localizationError,
    },
    fetchLocalization,
  ] = useAxios(
    {
      method: "POST",
      url: `/urs/lang`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (localizationData) {
      sendScripterMessage({
        type: "fix_scripter_preview",
        scripts: localizationData,
      });
      recieveMessage(SCRIPTER_MESSAGES.PREVIEW_READY, (data) => {
        setValidators(normalizeValidatorsData(data.result));
      });

      recieveMessage(SCRIPTER_MESSAGES.PREVIEW_ERROR, () => {
        setValidators([]);
      });

    }
  }, [localizationData]);

  const normalizeValidatorsData = (data) => {
    return groupByErrorsPriority(
      data.ElementErrors.concat(data.DocumentErrors)
    );
  };

  const groupByErrorsPriority = (items) => {
    const key = "user_impact_text";
    const reducer = (accumulator, currentValue) => {
      try {
        (accumulator[validatorsInfo[currentValue.Code][key]] =
          accumulator[validatorsInfo[currentValue.Code][key]] || []).push(
            currentValue
          );
      } catch (error) {
        //console.error("Error grouping validators error:", error);
      } finally {
        return accumulator;
      }
    };
    const grouped = items.reduce(reducer, {});
    Object.keys(grouped).forEach((key) =>
      grouped[key].sort(
        (a, b) => (b.Elements?.length ?? 0) - (a.Elements?.length ?? 0)
      )
    );
    return grouped;
  };

  useEffect(() => {
    if (scriptsData) {
      sendScripterMessage({
        type: SCRIPTER_MESSAGES.INIT_SCRIPTER_PREVIEW,
        scripts: scriptsData,
        isPreviewMode: Storage.GetItem(SCRIPTER_PREVIEW_MODE),
      });
      recieveMessage(SCRIPTER_MESSAGES.INIT_SCRIPTER_PREVIEW, data => {
        (async () => {
          var url = await getCurrentUrl();
          if (url) {
            fetchLocalization({
              data: { url: url, lang: data.lang },
            });
          }
        })();
      });
    }
  }, [scriptsData]);

  useEffect(() => {
    (async () => {
      var url = await getCurrentUrl();
      const profile = Storage.GetItem(STORAGE_NAMES.PROFILE)
      if (url) {
        fetchScripts({
          data: { url, profile },
        });
      }
    })();
  }, []);

  return (
    <Layout style={{ background: "transparent" }}>
      <PreviewHeader />
      <PreviewProvider>
        <Routes>
          <Route path="/" element={<PreviewContent validatorsErrors={validators}/>} />
          <Route path="error" element={<ErrorDetailsContainer validatorsErrors={validators}/>} />
        </Routes>
      </PreviewProvider>
    </Layout >
  );
};

export default ScripterPreview;
