import React, { useEffect } from "react";
import { Spin } from "antd";
import { useAuth } from "./Auth/AuthContext";
import Login from "./Auth";
import styled from "styled-components";
import { recieveMessage, sendMessage } from "Shared/messages";
import { toastError } from "Shared/utils";
import "./App.less";
import { useGlobal } from "GlobalContext/GlobalContext";
import Scripter from "./Main/Views/index";
import { ScripterProvider } from "./Main/Context/ScripterContext";
import { SwitchProvider } from "Main/Context/SwitchContext";
import { VisibilityProvider } from "Main/Context/ElementVisibilityContext";
import PreviewSpiner from "Shared/Components/PreviewSpinner";
import { DomainProvider } from "Main/Views/Domains/context/DomainContext";

const StyledLoadingContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
${({ loading }) => loading && `background-color: #000000;`}
${({ loading }) => `height: ${loading ? "100vh;" : "calc(97vh - 40px);"}`}
`;

const App = () => {
  const { isLoggedIn } = useAuth();
  const { siteSettings } = useGlobal();

  useEffect(() => {
    recieveMessage("error", (msg) => {
      if (msg && msg.error) {
        toastError(msg.error);
      }
    });
    sendMessage({ type: "urs_loaded" });
  }, []);

  return isLoggedIn === null ? (
    <></>
  ) : isLoggedIn ? (
    !siteSettings ? (
      <Loading />
    ) : (
      <Main />
    )
  ) : (
    <Login />
  );
};

const Loading = () => {
  const { isLoginError } = useAuth();

  const StyledLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ loading }) => loading && `background-color: #000000;`}
    ${({ loading }) => `height: ${loading ? "100vh;" : "calc(97vh - 40px);"}`}
  `;

  return (
    <StyledLoadingContainer loading="true">
      <PreviewSpiner />
    </StyledLoadingContainer>
  )
}
const Main = () => {
  return (
    <VisibilityProvider>
      <SwitchProvider>
        <ScripterProvider>
          <DomainProvider>
            <Scripter />
          </DomainProvider>
        </ScripterProvider>
      </SwitchProvider>
    </VisibilityProvider>
  )
}
export default App;

