import React, { useState, useEffect } from "react";
import { Input, Checkbox, Button } from "antd";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { StyledModal } from "Main/Shared/ModalStyles";
import useAxios from "axios-hooks";
import { useVisibilityContext } from "Main/Context/ElementVisibilityContext";

const ReportModal = () => {
  const checkboxOptions = ["JAWS.CH", "NVDA.FF", "VoiceOver.IP", "TalkBack.AN"];
  const { reportModalVisibility, setReportModalVisibility } =
    useVisibilityContext();
  const [customerName, setCustomerName] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [checkboxList, setCheckbox] = useState(checkboxOptions);

  const [{ data: pdfdData, error: pdfError }, generateReport] = useAxios(
    {
      method: "POST",
      url: `/frontend/report`,
      headers: {
        "content-type": "application/json",
      },
      responseType: "arraybuffer",
    },
    { manual: true }
  );

  useEffect(() => {
    if (pdfdData) {
      setDownloadLoading(false);
      setReportModalVisibility(false);
      const file = new Blob([pdfdData], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `Close Out Report - ${customerName}.pdf`;
      link.click();
    }
  }, [pdfdData]);

  const onDownload = async () => {
    const url = await getCurrentUrl();
    if (url) {
      setDownloadLoading(true);
      generateReport({ data: { url, customerName, checkboxList } });
    }
  };

  return (
    <StyledModal
      mask="false"
      title="Generate and Download Close Out Report ?"
      visible={reportModalVisibility}
      onCancel={() => setReportModalVisibility(false)}
      footer={[
        <Button onClick={() => setReportModalVisibility(false)}>CANCEL</Button>,
        <Button onClick={onDownload} type="primary" loading={downloadLoading}>
          DOWNLOAD
        </Button>,
      ]}
    >
      <Input
        onChange={(e) => setCustomerName(e.target.value)}
        id="customer-name"
        addonBefore={<label for="customer-name">Customer Name</label>}
        value={customerName}
      />
      <Checkbox.Group
        options={checkboxOptions}
        defaultValue={checkboxOptions}
        onChange={(e) => setCheckbox(e)}
      />
    </StyledModal>
  );
};

export default ReportModal;
