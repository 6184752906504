import { useDomainContext } from "../../context/DomainContext"
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react';
import UButton from "Shared/Components/Button/UButton";
import { Popconfirm } from "antd";
import { StyledInRowButtonsContainer } from "./style";
import { ACTION_TYPE, addAction } from "../../services/domain.service";


export const EditOperations = ({ record }) => {

    const { data, setData, editedRow, form, setEditingKey, addDomain, updateDomain, remove } = useDomainContext()

    const cancel = async () => {
        const idx = data.findIndex(row => row.key === editedRow.key)
        if (data[idx].beforeSave) {
            remove(data[idx])
            setEditingKey('');
            return
        }
        const beforeEditData = [...data]
        beforeEditData[idx] = editedRow
        await form.validateFields();
        setEditingKey('');
    };


    const save = async key => {
        try {
            const row = await form.validateFields();
            const newData = [...data];

            const index = newData.findIndex((item) => key === item.key);
            const previousDomain = data[index].domain
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setEditingKey('');
                if (newData[index].isNewlyAddedRow) {
                    delete newData[index].beforeSave
                    setData(newData);
                    addAction(ACTION_TYPE.ADD, newData[index], addDomain)
                } else {
                    newData[index].isEdited = true
                    setData(newData);
                    addAction(ACTION_TYPE.UPDATE, newData[index], updateDomain, previousDomain)
                }
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    return <StyledInRowButtonsContainer>
        <UButton onClick={() => save(record.key)} icon={<SaveOutlined />} title="Save Row" />
        <Popconfirm okText="Yes" cancelText="No" title="Are you sure you want to cancel?" onConfirm={() => { cancel(record) }}>
            <UButton icon={<CloseOutlined />} title="Cancel" />
        </Popconfirm>
    </StyledInRowButtonsContainer>

}