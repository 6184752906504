import React from "react";
import { Layout, Button } from "antd";
import { colors } from "Shared/colors";
import styled from "styled-components";
import { MinusSquareOutlined } from "@ant-design/icons";
import { addFolderIcon, addFileIcon, pdfIcon } from "Shared/Icons/Icons.js";
import { useScripter } from "../../../Context/ScripterContext";
import { useSwitch } from "Main/Context/SwitchContext";
import { useVisibilityContext } from "Main/Context/ElementVisibilityContext";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  align-items: center;
  display: flex;
  height: 40px;
  padding: 0 0 0 10px;
  background: ${colors.BLACK};
  color: white;
  z-index: 1;
  line-height: 2.2rem;
`;
const StyledButton = styled(Button)`
  :hover {
    color: white !important;
  }
  :focus {
    color: white !important;
  }
`;

const StyledSpan = styled.span`
  flex: 1;
  font-size: 10px;
`;

const FileActions = styled.div`
  img {
    cursor: pointer;
    position: absolute;
    top: 14px;
  }

  svg {
    position: absolute;
    top: 13px;
    right: 6px;
    height: 14px;
  }
`;

const TreeHeader = () => {
  const { CollapseAll, NewFolder, NewScript, sideBarCollapsed } = useScripter();
  const { setReportModalVisibility } = useVisibilityContext();

  return (
    <StyledHeader>
      <StyledSpan style={{ flex: 1 }}>EXPLORER</StyledSpan>

      {!sideBarCollapsed && (
        <FileActions>
          {/* <img
            style={{ right: "70px" }}
            onClick={() => setReportModalVisibility(true)}
            src={pdfIcon}
            title="Generate Report"
          /> */}
          <img
            style={{ right: "55px" }}
            onClick={NewScript}
            src={addFileIcon}
            title="Open A New File"
          />
          <img
            style={{ right: "30px" }}
            onClick={NewFolder}
            src={addFolderIcon}
            title="Open A New Folder"
          />
          <MinusSquareOutlined onClick={CollapseAll} title="Collapse" />
        </FileActions>
      )}
    </StyledHeader>
  );
};

export default TreeHeader;
