import { useDomainContext } from "../../context/DomainContext"
import { UndoOutlined } from '@ant-design/icons';
import React from 'react';
import UButton from "Shared/Components/Button/UButton";
import { undoAction } from "../../services/domain.service";
import { clearProps } from "../../services/table.service";



export const DeleteOperation = ({ record }) => {

    const { setData, data } = useDomainContext()




    const onUndo = record => {
        undoAction(record.key)
        clearProps('isDeleted', record)
        setData([...data]);
    }

    return <UButton onClick={() => onUndo(record)} icon={<UndoOutlined />} title="Undo Row" />

}