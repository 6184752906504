import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { colors } from '../../Shared/colors'
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { sendScripterMessage } from "Shared/messages";
import useAxios from "axios-hooks";
import { camelizeKeys } from 'humps';
import {
    updateScriptContent,
    fixReplaceTreeData,
    updateErrorDetected,
    renameUpdateTreeData,
    tempReplaceTreeData,
    tempUpdateTreeData,
    removeTempUpdateTreeData,
    getAllChildrenKeys,
    moveUpdateTreeData
} from "../Views/Sider/Tree/TreeManipulation";
import { uuidv4 } from "../Utils/Utils"
import { toastError } from "Shared/utils";
import { TITLES, ERRORS, PROTECTED_SCRIPTS_TITLES } from "Shared/consts";

const ScripterContext = createContext();

function ScripterProvider({ children }) {
    const [sideBarCollapsed, setSideBarCollapsed] = useState(false);
    const [sideBarColor, setSideBarColor] = useState(colors.LIGHT_BLUE);
    const [selectedTreeNode, setSelectedTreeNode] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [siderActiveTab, setSiderActiveTab] = useState(null);
    const [treeData, setTreeData] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [scopeModalProps, setScopeModalProps] = useState({visible: false, folderName: null, id: null});
    const [urlChangeProps, setUrlChangeProps] = useState({visible: false, originUrl: null, validationError: null, });
    const [scriptSaveData, setScriptSaveData] = useState(null);
    const [resizeFlag, setResizeFlag] = useState(false);
    const [common, setCommon] = useState(null);
    const [isCommonVisible, setIsCommonVisible] = useState(false);
    const [protectedScripts, setProtectedScripts] = useState([]);

    const nodeRefToDom = useRef(null);

    const [{ data: initData, loading: dataLoading, error: dataError }, fetchData,] = useAxios(
        {
            method: "POST",
            url: `/scripter/get-tree`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: validatorsData, loading: validatorsLoading, error: validatorsError }, fetchValidators,] = useAxios(
        {
            method: "POST",
            url: `/scripter/validators`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: createFolderData, loading: createFolderLoading, error: createFolderError }, CreateFolder,] = useAxios(
        {
            method: "POST",
            url: `/scripter/create-folder`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: createScriptData, loading: createScriptLoading, error: createScriptError }, CreateScript,] = useAxios(
        {
            method: "POST",
            url: `/scripter/create-script`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: deleteData, loading: deleteLoading, error: deleteError }, DeleteFile,] = useAxios(
        {
            method: "DELETE",
            url: `/scripter/delete`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: renameData, loading: renameLoading, error: renameError }, RenameFile,] = useAxios(
        {
            method: "PUT",
            url: `/scripter/rename`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: updateScriptData, loading: updateScriptLoading, error: updateScriptError }, updateScript,] = useAxios(
        {
            method: "PUT",
            url: `/scripter/update-script`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: moveData, loading: moveLoading, error: movetError }, moveScript,] = useAxios(
        {
            method: "PUT",
            url: `/scripter/move`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    useEffect(() => {
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                fetchData({data: { url }});
                fetchValidators({data: { url }});
            }
        })();
    }, []);

    useEffect(() => {
        if (initData) {
            setTreeData(camelizeKeys(initData.children));
            setCommon(initData.children.filter(node => node.title === 'common.js')[0]?.key);
            const protectedScriptsKeys = initData.children
                .filter(node => PROTECTED_SCRIPTS_TITLES.includes(node.title))
                .map(node => node.key);
            setProtectedScripts(protectedScriptsKeys);
        }
    }, [initData]);

    useEffect(() => {
        if (validatorsData) {
            sendScripterMessage({ type: "init_scripter_validation", validators: validatorsData });
        }
    }, [validatorsData]);

    useEffect(() => {
    }, [tabs]);

    const SetTreeNode = (node) => {
        if (!selectedTreeNode || node.key !== selectedTreeNode.key) {
            setSelectedTreeNode(node);
            if (node.isLeaf && node.key != activeTab?.key) {
                if (!tabs || !tabs.some(t => t.key === node.key)) {
                    setTabs([...tabs, node]);
                }
                setActiveTab(node);
            }
        }
    }

    const SetSearchTreeNode = (node) => {
        setSelectedTreeNode(node);
        if (!tabs || !tabs.some(t => t.key === node.key)) {
            setTabs([...tabs, node]);
        }
        setActiveTab(node);
    }



    const SetTab = (key) => {
        if (!activeTab || key !== activeTab?.key) {
            const treeNode = tabs.find(t => t.key === key);
            SetTreeNode(treeNode);
            AdjustExpandedKeys(treeNode);
        }
    }

    const AdjustExpandedKeys = (treeNode) => {
        const path = treeNode?.path ? [...treeNode.path] : [];
        if (!treeData.isLeaf) {
            path.push(treeNode.key);
        }
        const mergePathToExpandedKeys = expandedKeys.concat(path);
        setExpandedKeys([...new Set(mergePathToExpandedKeys)]);
    }

    const removeTabs = (keys) => {
        const newTabs = tabs.filter(tab => !keys.includes(tab.key));
        setTabs(newTabs);
        if (newTabs.length && keys.includes(activeTab?.key)) {
            const newActive = newTabs[0];
            setActiveTab(newActive);
            SetTreeNode(newActive);
            AdjustExpandedKeys(newActive);
        }
    };

    const removeTab = (targetKey) => {
        const activeKey = activeTab?.key;
        let lastIndex;
        tabs.forEach((tab, i) => {
            if (tab.key === targetKey) lastIndex = i - 1;
        });
        
        const newTabs = targetKey === common ? tabs : tabs.filter(tab => tab.key !== targetKey);
        if (targetKey === common) setIsCommonVisible(false);
        targetKey !== common && setTabs(newTabs);
        
        let newActive;
        let isEmptySelected = false;
        if (newTabs.length > 1 && activeKey === targetKey) newActive = lastIndex >= 1 ? newTabs[lastIndex] : newTabs[1];
        if (newTabs.length === 1 && isCommonVisible && targetKey !== common) newActive = newTabs[0];
        if (newTabs.length === 1 && !isCommonVisible && targetKey !== common) isEmptySelected = true;
        
        if(newActive) {
            setActiveTab(newActive);
            SetTreeNode(newActive);
            AdjustExpandedKeys(newActive);
        }
        if (isEmptySelected) {
            setSelectedTreeNode(null);
            setActiveTab(null);
        }
    };

    const changeTabName = (targetKey, title) => {
        if (activeTab?.key === targetKey) {
            setActiveTab({ ...activeTab, title })
        }
        setTabs(tabs => tabs.map(t => t.key === targetKey ? { ...t, title } : t));
    };


    const changeAcordingToMarkers = (targetKey, changeDetected, errorDetected) => {
        const newTab = tabs.filter(t => t.key === targetKey)[0];
        newTab.changeDetected = changeDetected;
        newTab.errorDetected = errorDetected;
        //{ ...activeTab, changeDetected, errorDetected };
        setActiveTab(newTab);
        setTabs(tabs => tabs.map(t => t.key === targetKey ? newTab : t));
        setTreeData(updateErrorDetected(treeData, targetKey, errorDetected));
    };


    const CollapseAll = () => {
        setExpandedKeys([]);
    }
    const NewFile = (isLeaf) => {
        AdjustExpandedKeys(selectedTreeNode ?? {});
        const key = uuidv4()
        const path = getPath()
        const data = {
            key,
            path,
            isLeaf
        }
        const newItem = {
            key,
            title: <span
                data={JSON.stringify(data)}
                onBlur={CreateNewItem}
                onKeyPress={e => (e.keyCode || e.which) === 13 && e.target.blur()}
                contentEditable="true"
                ref={nodeRefToDom} />,
            isLeaf
        };
        const newTree = tempUpdateTreeData(treeData, path.length ? path[path.length - 1] : false, newItem);
        setTreeData(newTree);
    }

    const NewScript = () => {
        NewFile(true);
    }

    const NewFolder = () => {
        NewFile(false);
    }

    const CreateNewItem = () => {
        const node = nodeRefToDom.current;
        let title = node.textContent.trim();
        const data = JSON.parse(node.getAttribute("data"));
        if (title && title !== "" && isValidScriptTitle(title, data, false)) {
            if (data.isLeaf) {
                title = adjustTitleWithExtension(title);
            }
            data.title = title;
            (async () => {
                var url = await getCurrentUrl();
                if (url) {
                    data.url = url;
                    if (data.isLeaf) {
                        CreateScript({ data });
                        data.content = "";
                        data.originUrl = url;
                    } else {
                        CreateFolder({ data });
                        data.children = [];
                        data.childrenKeys = "";
                    }
                }
            })();
            const path = data.path;
            const newTree = fixReplaceTreeData(treeData, path.length ? path[path.length - 1] : false, data);
            setTreeData(newTree);
            SetTreeNode(data);
        }
        else {
            const path = data.path;
            const newTree = removeTempUpdateTreeData(treeData, path.length ? path[path.length - 1] : false, data);
            setTreeData(newTree);
        }
    }

    const MoveTreeNode = (fromNode, toNode, dropToGap) => {
        if (toNode.isLeaf && dropToGap === false) return;
        const removeFromFolder = fromNode.path.length ? fromNode.path[fromNode.path.length - 1] : false;
        const insertToFolder = dropToGap ? (toNode.path.length ? toNode.path[toNode.path.length - 1] : false) : toNode.key;
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                moveScript({
                    data: {
                        url,
                        key: fromNode.key,
                        remove_from: removeFromFolder !== false ? removeFromFolder : null,
                        add_to: insertToFolder !== false ? insertToFolder : null
                    }
                });
                let newTree = removeTempUpdateTreeData(treeData, removeFromFolder, fromNode);
                newTree = moveUpdateTreeData(newTree, insertToFolder, fromNode);
                setTreeData(newTree);
            }
        })();
    }

    const DeleteItem = (node) => {
        if(isTitleProtected(node.title)) {
            toastError(ERRORS.INVALID_DELETE(node.title));
            return;
        }
        const allNodesToRemove = getAllChildrenKeys(node);
        removeTabs(allNodesToRemove);
        const data = {
            key: node.key,
            path: node.path,
            all_nodes_to_remove: allNodesToRemove
        };
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                data.url = url;
                DeleteFile({ data: data });
                const path = data.path;
                const newTree = removeTempUpdateTreeData(treeData, path.length ? path[path.length - 1] : false, data);
                setTreeData(newTree);
            }
        })();

    }

    const SaveScript = (activeTab, newText, errorDetected) => {
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                updateScript({ data: { url, key: activeTab.key, content: newText } });
            }
        })();
        const newTab = { ...activeTab, changeDetected: false, content: newText, errorDetected };
        setActiveTab(newTab);
        setTabs(tabs => tabs.map(t => t.key === newTab.key ? newTab : t));
        setTreeData(updateScriptContent(treeData, newTab));
    }

    const adjustTitleWithExtension = (title) => {
        if (title.split(".").length == 1) {
            title += ".js";
        }
        return title;
    }

    const UpdateItemName = () => {
        const node = nodeRefToDom.current;
        const data = JSON.parse(node.getAttribute("data"));
        let title = node.textContent.trim();
        if (!data.is_folder) {
            title = adjustTitleWithExtension(title);
        }
        if (title && title !== "" && title !== data.title && isValidScriptTitle(title, data, true)) {
            data.title = title;
            (async () => {
                var url = await getCurrentUrl();
                if (url) {
                    data.url = url;
                    RenameFile({ data });
                }
            })();
            changeTabName(data.key, data.title);
        }
        const path = data.path;
        const newTree = renameUpdateTreeData(treeData, path.length ? path[path.length - 1] : false, data);
        setTreeData(newTree);
    }

    const Rename = (node) => {
        const data = {
            key: node.key,
            title: node.title,
            path: node.path,
            is_folder: node.isLeaf ? false : true
        }
        const newItem = {
            key: node.key,
            title: <span
                data={JSON.stringify(data)}
                onBlur={UpdateItemName}
                onFocus={() => { document.execCommand('selectAll') }}
                onKeyPress={e => (e.keyCode || e.which) === 13 && e.target.blur()}
                contentEditable="true"
                ref={nodeRefToDom}>{node.title}</span>,
            isLeaf: node.isLeaf ?? false,
            childrenKeys: node.childrenKeys ?? "",
            children: node.children ?? [],
            path: node.path
        };
        const newTree = tempReplaceTreeData(treeData, newItem.path.length ? newItem.path[newItem.path.length - 1] : false, newItem);
        setTreeData(newTree);
    }

    const isValidScriptTitle = (title, data, isRename) => {
        if (!isValid(title)) {
            toastError(ERRORS.INVALID_INCLUDE_TITLE);
            return false;
        }

        if (isRename && isTitleProtected(data.title)) {
            toastError(ERRORS.INVALID_RENAME_TITLE(data.title));
            return false;
        }

        if (isScript(data, isRename) && isTitleProtected(title)) {
            toastError(ERRORS.INVALID_TITLE(data.title));
            return false;
        }

        return true;
    }

    const isValid = (title) => {
        return /^[A-Za-z0-9-._]*$/.test(title);
    }
    
    const isScript = (data, isRename) => isRename ? !data.is_folder : data.isLeaf;

    const isTitleProtected = (title) => PROTECTED_SCRIPTS_TITLES.includes(`${title}.js`) || PROTECTED_SCRIPTS_TITLES.includes(title);
    
    useEffect(() => {
        setTimeout(() => {
            if (nodeRefToDom?.current) {
                nodeRefToDom.current.focus();
            }
        }, 400)
    }, [treeData]);

    const getPath = () => {
        if (!selectedTreeNode) return [];
        const path = [...selectedTreeNode.path];
        if (!selectedTreeNode.isLeaf) {
            path.push(selectedTreeNode.key);
        }
        return path;
    }


    return (
        <ScripterContext.Provider
            value={
                {
                    sideBarColor,
                    setSideBarColor,
                    sideBarCollapsed,
                    setSideBarCollapsed,
                    selectedTreeNode,
                    setSelectedTreeNode: SetTreeNode,
                    tabs,
                    activeTab,
                    setActiveTab: SetTab,
                    expandedKeys,
                    setExpandedKeys,
                    removeTab,
                    CollapseAll,
                    NewScript,
                    NewFolder,
                    treeData,
                    DeleteItem,
                    Rename,
                    changeAcordingToMarkers,
                    SaveScript,
                    MoveTreeNode,
                    AdjustExpandedKeys,
                    searchValue,
                    setSearchValue,
                    SetSearchTreeNode,
                    scopeModalProps, 
                    setScopeModalProps,
                    urlChangeProps,
                    setUrlChangeProps,
                    scriptSaveData,
                    setScriptSaveData,
                    siderActiveTab,
                    setSiderActiveTab,
                    resizeFlag,
                    setResizeFlag,
                    common,
                    setCommon,
                    isCommonVisible,
                    setIsCommonVisible,
                    protectedScripts,
                }
            }>
            {children}
        </ScripterContext.Provider>
    );
}

function useScripter() {
    const context = useContext(ScripterContext);
    if (context === undefined) {
        throw new Error(`useScripter must be used within a TreeContext`);
    }
    return context;
}

export { ScripterProvider, useScripter };
