import styled from "styled-components";
import { Menu, Button } from 'antd';

const { SubMenu } = Menu;

const MenuItem = styled(Menu.Item)`
&:hover{
    background-color: #4E0E7E;
}
color: white;
font-size: 12px;
border: 5px solid black;
border-radius: 10px;
${props => props.activeItem ?
        `background-color: #4E0E7E;` :
        `background-color: black;`
    }; 
`;

const StyledSubMenu = styled(SubMenu)`
  background-color: ${({activeItem}) => activeItem ? '#4E0E7E' : 'black'};
  border-radius: 10px;
  &:hover .ant-dropdown-menu-submenu-title {
    background-color: transparent;
  }
  &:hover {
    background-color: #4e0e7e;
    
  }
  .ant-dropdown-menu-title-content,
  svg {
    color: white;
  }
  border: 5px solid black;
`;

const StyledMenu = styled(Menu)`
    background: black;
    margin-top: -8px;
    box-shadow: 5px 5px 10px rgb(0,0,0, 0.5);
    font-size: 12px !important;
    bodrer-radius: 5px;
`;

const MenuButton = styled(Button)`
    &:hover{
        background-color: #050125;
        opacity: 0.7;
        color: #FAFAFA;
    }
    &:focus{
        background-color: black;
        opacity: 1;
        color: #C0AFDA;
    }
    border: none;
    border-radius: 5px;
    width: 120px;
    text-align: left;
    opacity: 0.7;
    height: 35px;
    text-transform: capitalize;
    ${props => props.ismenuvisible == 'true' ?
        `background-color: black;
        opacity: 1;
        color: #C0AFDA;
        
        &:hover{
            background-color: black !important;
            opacity: 1 !important;
            color: #C0AFDA !important;
        }`

        :

        `background-color: #050125 !important;
            opacity: 0.7 !important;
            color: #FAFAFA !important;
            `
    };

`;


export { MenuItem, StyledMenu, MenuButton, StyledSubMenu };