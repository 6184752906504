import React from "react";
import {
  PRODUCT,
  ADDRESS,
  MESSAGES,
  KEYS,
  SCRIPTER_PREVIEW_MODE,
} from "Main/Views/consts";
import { StyledMenu, MenuItem, StyledSubMenu } from "Main/Shared/MenuStyles";
import { useSwitch } from "Main/Context/SwitchContext";
import { sendMessage } from "Shared/messages";
import { Storage } from "Shared/Services/storage";

const StyledDropdown = () => {
  const {
    activeAddress,
    setActiveAddress,
    activeProd,
    setActiveProd,
    changeProduct,
    changeAddress,
  } = useSwitch();

  const activateProduct = (prodToActivate) => {
    const prevProd = activeProd;
    changeProduct(prodToActivate);
    setActiveProd(prodToActivate);

    const previewMode = Storage.GetItem(SCRIPTER_PREVIEW_MODE);
    const shouldReload =
      prevProd == PRODUCT.FRONTEND ||
      previewMode == "true" ||
      prodToActivate == PRODUCT.FRONTEND;
    if (shouldReload) {
      Storage.RemoveItem(SCRIPTER_PREVIEW_MODE);
      sendMessage({ type: MESSAGES.RELOAD });
    }
  };

  const onMenuItemClicked = () =>  sendMessage({ type: "minimize", data: false, isDropdownClicked: false });

  return (<StyledMenu mode="vertical" onClick={onMenuItemClicked}>
      <MenuItem activeItem={activeProd ? activeProd == KEYS.TOOLBAR : undefined} key={KEYS.TOOLBAR}  >
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            activateProduct(PRODUCT.TOOLBAR);
          }}
        >
          Toolbar
        </a>
      </MenuItem>
      <MenuItem activeItem={activeProd == KEYS.FRONTEND} key={KEYS.FRONTEND}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            activateProduct(PRODUCT.FRONTEND);
          }}
        >
          Frontend
        </a>
      </MenuItem>
      <MenuItem activeItem={activeProd == KEYS.IDE} key={KEYS.IDE}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            activateProduct(PRODUCT.IDE);
          }}
        >
          IDE
        </a>
      </MenuItem>
      <StyledSubMenu
        key="sub"
        title={<span style={{ fontSize: "12px" }}>DEV</span>}
      >
        <MenuItem
          activeItem={activeAddress == KEYS.PRODUCTION}
          key={KEYS.PRODUCTION}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              changeAddress(ADDRESS.PROD);
              setActiveAddress(KEYS.PRODUCTION);
              sendMessage({ type: MESSAGES.RELOAD });
            }}
          >
            Production
          </a>
        </MenuItem>
        <MenuItem
          activeItem={activeAddress == KEYS.LOCAL_HOST}
          key={KEYS.LOCAL_HOST}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              changeAddress(ADDRESS.LOCALHOST);
              setActiveAddress(KEYS.LOCAL_HOST);
              sendMessage({ type: MESSAGES.RELOAD });
            }}
          >
            Localhost
          </a>
        </MenuItem>
      </StyledSubMenu>
    </StyledMenu>
  );
};

export { StyledDropdown };
