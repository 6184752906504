import React from "react";
import { Layout } from "antd";
import { colors } from "Shared/colors";
import styled from "styled-components";
import TabControl from "./TabControl/TabControl";
import ScopeModal from "./ScopeModal";
import { useScripter } from "Main/Context/ScripterContext";
import UrlChangeModal from "./UrlChangeModal";
import ReportModal from "./ReportModal";
import PublishModal from "./PublishModal";
import { useVisibilityContext } from "Main/Context/ElementVisibilityContext";
import PackageModal from "./PackageModal";
import Domain from "../Domains/Domain";
import { DomainProvider } from "Main/Views/Domains/context/DomainContext";



const { Content } = Layout;
const StyledContent = styled(Content)`
  background: ${colors.DARK90};
`;

const ScripterContent = () => {
  const { scopeModalProps, urlChangeProps } = useScripter();
  const { isPublishModalVisible, reportModalVisibility, packageModalVisiblity, isDomainsVisible } =
    useVisibilityContext();

  return (
    <StyledContent>
      {!isDomainsVisible && <TabControl />}
      {scopeModalProps?.visible && <ScopeModal />}
      {reportModalVisibility && <ReportModal />}
      {urlChangeProps?.visible && <UrlChangeModal />}
      {isPublishModalVisible && <PublishModal />}
      {packageModalVisiblity && <PackageModal />}
      {isDomainsVisible && <DomainProvider><Domain /> </DomainProvider>}
    </StyledContent>
  );
};

export default ScripterContent;
