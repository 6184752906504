import React from "react";
import { useScripter } from "Main/Context/ScripterContext";
import { Modal, Button } from "antd";
import { sendScripterMessage } from "Shared/messages";
import { toastSuccess } from "Shared/utils";
import styled from "styled-components";
import { backgrounds } from "Shared/colors";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${backgrounds.PURPLE_GRADIENT};
    border-bottom: solid black 1px;
    box-shadow: 0px 5px 11px 4px rgb(0 0 0 / 15%);
    z-index: 123123123132131;
    padding: 10px 24px;
    border-radius: 6px 6px 0 0;

    .ant-modal-title {
      color: white !important;
      font-size: 0.9rem;
    }
  }

  .ant-modal-content {
    border-radius: 7px;
  }

  .ant-modal-close {
    top: -7px;

    .ant-modal-close-x {
      color: white;
      opacity: 0.5;
      height: 20px;
    }
  }

  .ant-modal-footer {
    border: none;

    .ant-btn-primary {
      background: ${backgrounds.PURPLE_GRADIENT};
      border: none;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

const UrlChangeModal = () => {
  const { urlChangeProps, setUrlChangeProps, scriptSaveData, SaveScript } =
    useScripter();

  const goToUrl = () => {
    sendScripterMessage({ type: "location", url: urlChangeProps.originUrl });
  };

  const handleSave = () => {
    SaveScript(
      scriptSaveData.thisTab,
      scriptSaveData.editorValue,
      scriptSaveData.errors
    );
    setUrlChangeProps({
      visibile: false,
      originUrl: null,
      validationError: null,
    });
    toastSuccess("Changes saved successfully!");
  };

  return (
    <StyleModal
      title={`Change url to ${urlChangeProps.originUrl} ?`}
      visible={urlChangeProps.visible}
      footer={[
        <Button key="save" onClick={handleSave}>
          Save anyway
        </Button>,
        <Button key="origin" type="primary" onClick={goToUrl}>
          Go to url
        </Button>,
      ]}
    >
      <p>{urlChangeProps.validationError}</p>
      <p>It seems this script was originaly written on a different url.</p>
      <p>
        Would you like to go to <b>{urlChangeProps.originUrl}</b> ?
      </p>
    </StyleModal>
  );
};

export default UrlChangeModal;
