import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import highlightSVG from "./highlight.svg";
import copySVG from "./copy.svg";
import { sendHighlightMessage } from "Shared/messages";

const Preformatted = styled.pre`
  font-size: 12px;
  padding: 5px 10px;
  margin: 0;
  overflow: hidden;
  word-wrap: break-word;
`;

const SelectorContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const OverlayContainer = styled.div`
  position: absolute;
  align-self: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 85%;
`;

const OverlayButton = styled.button`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  gap: 3px;

  color: white;
  background-color: #00000080;
  border: none;
  padding: 10px 0px;
  font-size: 10px;

  border-radius: 5px;

  :hover {
    background-color: #3c3c3ccc;
    cursor: pointer;
  }

  :first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  :last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const CopyHighlightSelector =
  (selector, activeHighlightSelector, setActiveHighlightSelector, isVisible) =>
  (props) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(
      activeHighlightSelector === selector
    );
    const [isHovered, setIsHovered] = useState(
      activeHighlightSelector === selector
    );

    const handleCopyClick = () => {
      navigator.clipboard.writeText(selector);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    };

    const handleHighlightClick = () => {
      window.parent.postMessage(
        {
          type: `${isHighlighted ? "hide" : "show"}_error_element_highlight`,
        },
        "*"
      );
      window.parent.postMessage(
        { type: "highlight_error_element", selector },
        "*"
      );
      setActiveHighlightSelector(selector);
      setIsHighlighted((isHighlighted) => !isHighlighted);
    };

    return (
      <SelectorContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <OverlayContainer>
            <OverlayButton
              css={css`
                &[aria-pressed="true"] {
                  background-color: #3c3c3ccc;
                }
              `}
              disabled={!isVisible}
              onClick={handleHighlightClick}
              aria-pressed={isHighlighted}
            >
              <img src={highlightSVG} />
              {isHighlighted
                ? "Unhighlight"
                : `Highlight${isVisible ? "" : " (Disabled)"}`}
            </OverlayButton>
            <OverlayButton onClick={handleCopyClick}>
              {isCopied ? (
                "Copied!"
              ) : (
                <>
                  <img src={copySVG} />
                  Copy
                </>
              )}
            </OverlayButton>
          </OverlayContainer>
        )}
        <Preformatted onClick={handleCopyClick}>{props.children}</Preformatted>
      </SelectorContainer>
    );
  };

export default CopyHighlightSelector;
