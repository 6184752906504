import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./Auth/AuthContext";
import { GlobalProvider } from "GlobalContext/GlobalContext";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";


const root = (
  <BrowserRouter>
    <AuthProvider>
      <GlobalProvider>
        <div id="app">
          <App />
        </div>
      </GlobalProvider>
    </AuthProvider>
  </BrowserRouter>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
