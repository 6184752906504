import React, { useEffect, useState } from "react";
import { List } from "antd";
import { validatorsInfo } from "../../validatorsInfo";
import styled from "styled-components";
import { colors } from "Shared/colors";
import ErrorAvatar from "./ErrorAvatar";
import ErrorText from "./ErrorText";
import { priorityColors } from "../colors";
import { useScripterPreview } from "Main/Context/PreviewContext";
import { useNavigate } from "react-router-dom";
import PreviewSpiner from "../Utils/PreviewSpiner";

const { Item } = List;
const StyledList = styled(List)`
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 128px - 0.15rem);
  background-color: ${colors.SCRIPTER_PREVIEW_BLACK};
  margin: 0.15rem 0.3rem 0.15rem 0rem;

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #404040;
  }
  .ant-list-item-meta {
    display: contents;
  }
  .ant-list-item {
    border: 0px;
    padding: 0.75rem;
    :hover {
      cursor: pointer;
      background-image: linear-gradient(-90deg, #333 0%, #000 100%);
    }
  }
`;

const StyledListItem = styled(Item)`
  ${(props) => props.changes && "animation-name: fadeIn;"}
  ${(props) => props.changes && "animation-duration: 1s;"}
${(props) => props.changes && "background-color: #222;"}

@keyframes fadeIn {
    0% {
      background-color: #111;
    }
    25% {
      background-color: #333;
    }
    75% {
      background-color: #444;
    }
    100% {
      background-color: #222;
    }
  }
`;
const ValidatorsErrorsContainer = ({ validatorsErrors }) => {
  const [data, setData] = useState(null);
  const { setActiveError } = useScripterPreview();
  const [newChanges, setNewChanges] = useState(undefined);
  const [prevErrors, setPrevErrors] = useState(undefined);
  const [firstRunFlag, setFirstRunFlag] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const priority = ["Blocker", "Critical", "Major", "Minor", "Low"];
    if (validatorsErrors) {
      setData(
          priority.map((p) => validatorsErrors[p] && Object.values(validatorsErrors[p]))
                  .flat()
                  .filter((i) => i)
      );
    }
  }, [validatorsErrors]);

  useEffect(() => {
    if (data) {
      setNewChanges(undefined);
      const errors = {};
      const changes = {};
      for (let err of data) {
        if (err.Elements) {
          //ElementErrors
          errors[err.Code] = err.Elements.map((e) => e.Id);
          if (prevErrors && prevErrors[err.Code]) {
            if (
              prevErrors[err.Code].length == errors[err.Code].length &&
              errors[err.Code].every((currErr) =>
                prevErrors[err.Code].includes(currErr)
              )
            ) {
              changes[err.Code] = false;
            } else {
              changes[err.Code] = true;
            }
          } else {
            changes[err.Code] = true;
          }
        } else {
          //DocumentErrors
          errors[err.Code] = true;
          if (prevErrors && prevErrors[err.Code]) {
            changes[err.Code] = false;
          } else {
            changes[err.Code] = true;
          }
        }
      }
      setFirstRunFlag((curr) => (curr += 1));
      setPrevErrors(errors);
      setTimeout(() => setNewChanges(changes), 100);
    }
  }, [data]);

  return data ? (
    <StyledList
      itemLayout="horizontal"
      dataSource={data}
      size="small"
      renderItem={(item) => (
        <StyledListItem
          changes={
            firstRunFlag > 1
              ? newChanges
                ? newChanges[item.Code]
                  ? "true"
                  : undefined
                : undefined
              : undefined
          }
          key={item.Code + Math.floor(Math.random() * 1000000000)}
          onClick={() => {
            setActiveError(item);
            navigate("/error");
          }}
        >
          <List.Item.Meta
            avatar={
              <ErrorAvatar
                color={
                  priorityColors[validatorsInfo[item.Code]["user_impact_text"]]
                }
                count={
                  item.Elements?.length
                    ? item.Elements?.length > 99
                      ? "99+"
                      : item.Elements?.length
                    : null
                }
              />
            }
            description={
              <ErrorText text={validatorsInfo[item.Code].title ?? item.Code} />
            }
          />
        </StyledListItem>
      )}
    />
  ) : (
    <PreviewSpiner />
  );

  // validatorsErrors && priority.map(p =>
  //     validatorsErrors[p] && Object.values(validatorsErrors[p]).map((e, i) =>
  //         <ValidatorError key={e.Code} error={e} />

  //     )
  // )
};

export default ValidatorsErrorsContainer;
