
export const mergedColumns = (columns, isEditing, getRowsData) => {
    return columns.map((col) => {
        let inputType = 'text'

        if (!col.editable) {
            return col;
        }

        if (col.dataIndex === 'desktop' || col.dataIndex === 'mobile') {
            inputType = 'select'
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                getRowsData
            }),
        };
    });
}

export const domainColumns = [{
    title: 'Site Id',
    dataIndex: 'siteId',
    width: '15%',
    editable: true,
    sorter: (a, b) => a.siteId - b.siteId,
},
{
    title: 'Domain',
    dataIndex: 'domain',
    width: '40%',
    editable: true,
    sorter: (a, b) => a.domain.localeCompare(b.domain)
},
{
    title: 'Desktop',
    dataIndex: 'desktop',
    width: '15%',
    editable: true,
    sorter: (a, b) => a.desktop.localeCompare(b.desktop)
},
{
    title: 'Mobile',
    dataIndex: 'mobile',
    width: '15%',
    editable: true,
    sorter: (a, b) => a.mobile.localeCompare(b.mobile)
},
{
    title: 'Date',
    dataIndex: 'date',
    width: '15%',
    sorter: (a, b) => a.date.localeCompare(b.date)
}]