import styled from "styled-components"
import { Input } from 'antd';


export const StyledButtonsContainer = styled.div`
& > * {
    margin: 4px
}
`


export const StyledSearchInput = styled(Input.Search)`
 max-width:200px ;
  color: #838383;
  
  .ant-input-wrapper {
    display: flex;
  }

  .ant-input-wrapper * {
    font-size: 13.5px !important;
  }
  
  .ant-input {
    order: 2;
    float: none;
    display: table-cell;
    border-color: transparent;
    border-radius: 0 4px 4px 0 !important;  
    color: #fff;
  }   
  
  .ant-input:hover {
    border-color: #360095;
  }    
  
  .ant-input:focus {
    border-color: #4E0E7E;
    box-shadow: none;
  }  
  
  .ant-input::placeholder {
    color: #8B8B8B;
  }
  
  .ant-input-group-addon {
    order: 1;
    display: contents;
  }

  .ant-input-search-button {
    width: 36px;
    background: linear-gradient(156deg, #360095, #4E0E7E) !important;
    height: 35px;
    border: none;
    border-radius: 4px 0 0 4px !important;
  }

  .ant-input-search-button > img {
    display: inline-flex;
    margin-left: -5px;
  }
    
  .ant-input {
    background-color: #161616;
  }
    
  .anticon-search > svg {
    fill: #838383;
  }
`

export const HeaderOperatioContainer = styled.div`
display: flex;
margin:  20px 20px 10px;
align-items: center;
justify-content: space-between;
`