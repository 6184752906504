import React, { useState } from 'react'
import { Layout, Button, Input } from 'antd';
import { colors } from 'Shared/colors';
import styled from "styled-components";
import { useScripter } from "../../../Context/ScripterContext"

const { Header } = Layout;

const StyledHeader = styled(Header)`
    align-items: center;
    display:flex;
    height:40px;
    padding:0 0 0 10px;
    background: ${colors.BLACK};
    color: white;
    z-index: 1;
    line-height: 2.2rem;
    
`
const StyledButton = styled(Button)`
:hover{
    color:white !important;
}
:focus{
    color:white !important;
}
`

const StyledInput = styled(Input)`
    background: ${colors.GRAY};
    color: white;
    width: 200px;
    height: 25px;
    border: none;

    input{
        color : white;
        background: ${colors.GRAY};
    }
    .anticon {
        :hover{
            color: ${colors.BLUE};
        }
        color:white;
    }
    :hover{
        border-color: ${colors.BLUE};
    }
    :focus{
        border-color: ${colors.BLUE};
    }
`;

const StyledTreeNode = styled.span`
    color: white;
`;

const Parent = styled.span`
    color: gray;
`;

const SearchHeader = ({ setSearchData, setSearchQuery }) => {
    const [timeoutFlag, setTimeoutFlag] = useState(null);
    const { treeData } = useScripter();

    const filterTree = (result, tree, query, parentTitle = "") => {
        const lowerCaseQuery = query.toLowerCase();
        if (!query || query.trim() === "") return [];
        tree.forEach(n => {
            if (n.isLeaf) {
                if ((n.title).toLowerCase().indexOf(lowerCaseQuery) !== -1 || (n.content).toLowerCase().indexOf(lowerCaseQuery) !== -1) {
                    let tmpNode = {}
                    Object.assign(tmpNode, n);
                    tmpNode.title = (
                        <StyledTreeNode title={n.title}>
                            <Parent> {parentTitle}</Parent>
                            {n.title}
                        </StyledTreeNode>
                    )
                    result.push(tmpNode);
                }
            } else {
                let parent = parentTitle + n.title + "/";
                filterTree(result, n.children, query, parent);
            }
        })
        return result;
    }

    const search = (query, forceNowFlag) => {
        setSearchQuery(query);
        if (timeoutFlag) {
            clearTimeout(timeoutFlag);
        }
        if (forceNowFlag) {
            setSearchData(filterTree([], treeData, query));
        } else {
            setTimeoutFlag(setTimeout(() => setSearchData(filterTree([], treeData, query)), 500));
        }
    }

    return (
        <StyledHeader>
            <StyledInput
                allowClear
                onChange={e => search(e.target.value, false)}
                onPressEnter={e => search(e.target.value, true)}
                placeholder="Search" />
        </StyledHeader>
    )
}

export default SearchHeader;