export const reOrderKeys = (prevArr, arr, idx) => {
    if (!prevArr) {
        arr.forEach((_, idx) => {
            arr[idx].key = idx.toString()
        })
        return
    }

    if (idx === prevArr.length - 1) return

    for (let i = idx; i < arr.length; i++) {
        try {
            arr[i].key = (parseInt(arr[i].key) - 1).toString()
        } catch (error) {
            console.error(`cannot parse ${arr[i].key} to int`);
        }
    }
}


export const clearProps = (prop, record, arr) => {
    if (!record && arr?.length) {
        arr.forEach(item => { if (item[prop]) delete item[prop] })
        return arr
    } else if (record) {
        delete record[prop]
    }
}