export const ERRORS = {
    INVALID_INCLUDE_TITLE: `Title can only include: letters, numbers, dots, dashes and underscores.`,
    INVALID_TITLE: (title)=> `Title cannot be: ${title}`,
    INVALID_RENAME_TITLE: (title) => `Cannot rename ${title} file.`,
    INVALID_DELETE: (title) => `Cannot delete ${title} file.`,
}

export const SCRIPTER_MESSAGES = {
    INIT_SCRIPTER_PREVIEW: "init_scripter_preview",
    FIX_SCRIPTER_PREVIEW: "fix_scripter_preview",
    INIT_SCRIPTER_VALIDATION: "init_scripter_validation",
    GET_TOOLBAR_PRODUCT: "get-product-name",
    SAVE_SCRIPTER_VALIDATION: "save_scripter_validation",
    GET_SELECTORS_FOR_ERR_CODE: "get_selectors_for_err_code",
    SELECTOR_READY: "selector_ready",
    PREVIEW_READY: "preview_ready",
    PREVIEW_ERROR: "preview_error",
    NEW_TAB: "open_new_tab",
    CLOSE_TAB: "close_tab",
    LOCATION: "location",
    VALIDATE_SCOPE_SELECTOR: "validate_scope_selector",
};

export const TITLES = {
    COMMON: 'common',
}

export const PROTECTED_SCRIPTS_TITLES = ['grayScale.js', 'common.js', 'magnify.js', 'contrast.js', 'movingContent.js'];

export const MESSAGE_STATE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
}
