import React, { useState } from 'react';
import { Form } from 'antd';
import { useEffect } from 'react';
import { hasDeleteAction } from './services/domain.service';
import { EditableCell } from './components/EditableCells/EditableCells';
import { HeaderOperations } from './components/HeaderOperation/HeaderOperation';
import { domainColumns, mergedColumns } from './services/column.service';
import { DeleteOperation } from './components/operations/DeleteOperation';
import { DefaultOperations } from './components/operations/DefaultOperations';
import { EditOperations } from './components/operations/EditOperations';
import { DomainContainer, StyledTable } from './style';
import CardWithMessage from 'Shared/Components/CardWithMessage';
import { MESSAGE_STATE } from 'Shared/consts';
import { useDomainContext } from './context/DomainContext';


const Domain = () => {
    const [isNewlyAddedRow, setIsNewAddedRow] = useState(false)
    const [modifiedData, setModifiedData] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')

    const { data, form, editingKey, edit } = useDomainContext();

    useEffect(() => {
        if (isNewlyAddedRow) {
            edit(data[data.length - 1])
            setIsNewAddedRow(false)
        }
    }, [data])

    useEffect(() => {
        if (searchTerm) search()
        else setModifiedData(null)
    }, [searchTerm])

    const isEditing = record => record.key === editingKey
    const isDeleted = key => hasDeleteAction(key)


    const search = () => {
        const filteredData = data.filter(item => {
            return item.siteId.toString().includes(searchTerm)
                || item.domain.includes(searchTerm)
                || item.mobile.includes(searchTerm)
                || item.desktop.includes(searchTerm)
        })
        setModifiedData(filteredData)
    }

    const onSearch = event => {
        const val = event.currentTarget.value.toLowerCase()
        setSearchTerm(val)
    }

    const columns = [
        ...domainColumns,
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                const deleted = isDeleted(record.key)
                return editable ? <EditOperations record={record} />
                    : deleted ? <DeleteOperation record={record} />
                        : <DefaultOperations record={record} />
            },
        },
    ];

    return (
        <DomainContainer>
            <HeaderOperations
                setIsNewAddedRow={setIsNewAddedRow}
                searchTerm={searchTerm}
                onSearch={onSearch}
                dataSource={modifiedData || data}
            />
            {data?.length &&
                <Form form={form} component={false}>
                    <StyledTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={modifiedData || data}
                        columns={mergedColumns(columns, isEditing, () => [...data])}
                        rowClassName={(record) => {
                            if (record.isNewlyAddedRow) {
                                return 'editable-row newly-added'
                            }
                            if (record.isDeleted) {
                                return 'editable-row deleted'
                            }
                            if (record.isEdited) {
                                return 'editable-row edited'
                            }
                            return 'editable-row'
                        }}
                        pagination={false}
                    />
                </Form>
            }
        </DomainContainer>
    );
};

export default Domain

